import React, { useState } from "react";
import { View, ScrollView, Pressable } from "react-native";
import { useForm } from "react-hook-form";
import {
  ProgressBar,
  Colors,
  Portal,
  Dialog,
  Paragraph,
  Button,
} from "react-native-paper";
import * as Location from "expo-location";
import { toastr } from "../../../utils/messages";
import { useDispatch, useSelector } from "react-redux";
import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import CustomButton from "../../../components/buttons/CustomButton";
import CardGenerator from "../../../components/card/CardGenerator";
import { geoService, getLocalAtual } from "../../../api/denuncia";
import { Input } from "../../../components/form/Input";
import { BLACK, TUNDORA, WHITE } from "../../../utils/cores";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Picker } from "@react-native-picker/picker";

function Localizacao({ navigation }) {
  const dispatch = useDispatch();
  const [estaNoLocal, setEstaNoLocal] = useState(null);
  const [localConfirmado, setLocalConfirmado] = useState(false);
  const localDescrito = useSelector((state) => state?.cadastroDenuncia);
  const bairros = useSelector((state) => state?.bairros?.list || []);
  const [bairro, setBairro] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const dados = {
    logradouro: watch("logradouro"),
    bairro: bairros?.length > 0 ? bairro : watch("bairro"),
    numero: watch("numero"),
    complemento: watch("complemento"),
  };

  const getLocation = async () => {
    await Location.enableNetworkProviderAsync();
    const status = await Location.hasServicesEnabledAsync();

    if (!status) {
      toastr.error("Ative a localizacão no seu celular");
      return null;
    }

    let location = await Location.getCurrentPositionAsync();
    return location;
  };

  const getLocalizacaoAtual = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      toastr.warning(
        "Precisamos da autorização para o cadastro da demanda, nesse caso, precisa preencher os campos!"
      );
      return null;
    }

    dispatch({ type: "loading/loadingStart" });
    const latLng = await getLocation();
    const latitudeLongitude = [
      latLng?.coords?.latitude,
      latLng?.coords?.longitude,
    ];

    const encontrouEndereco =
      latitudeLongitude &&
      latitudeLongitude[0] !== undefined &&
      latitudeLongitude[1] !== undefined;

    dispatch({ type: "loading/loadingStop" });
    if (!encontrouEndereco) {
      toastr.error("Não conseguimos identificar onde está!");
      setEstaNoLocal("NAO");
      return null;
    }

    await getLocalAtual(latitudeLongitude?.toString());
  };

  const tudoPreenchido =
    dados?.logradouro !== undefined &&
    dados?.bairro !== undefined &&
    dados?.numero !== undefined &&
    dados?.logradouro !== "" &&
    dados?.bairro !== "" &&
    dados?.numero !== "";

  const onSubmit = (data) => {
    if (estaNoLocal === null) {
      toastr.warning("Informe se está na localização ou não!");
      return null;
    }

    if (estaNoLocal === "SIM") {
      const semEndereco =
        localDescrito?.logradouro === undefined ||
        localDescrito?.logradouro === "";
      if (semEndereco) {
        toastr.warning(
          "Endereço não identificado. Verifique se a localização do seu celular " +
            "está ativada e tente novamente mais tarde!"
        );
        setEstaNoLocal("NAO");
        return null;
      }

      dispatch({
        type: "cadastroDenuncia/localizacaoSent",
        payload: { estaNoLocal, ...localDescrito },
      });
      navigation.navigate("CadastroTurnosDiasFuncionamento");
      return null;
    }

    if (data.complemento) {
      dispatch({
        type: "cadastroDenuncia/complementoSent",
        payload: data.complemento,
      });
    }
    geoService(data.logradouro, data.numero, data.bairro);
  };

  const acaoBotao = () => {
    if (estaNoLocal === "NAO" && !tudoPreenchido) {
      toastr.warning(
        "Você precisa preencher todos os campos obrigatórios (*)!"
      );
      return null;
    }
    setLocalConfirmado(true);
  };

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      style={Styles.containerPrincipal}
    >
      <Portal>
        <Dialog
          visible={localConfirmado}
          onDismiss={() => setLocalConfirmado(false)}
          style={Styles.larguraWeb}
        >
          <Dialog.Content>
            <Paragraph>
              Confirma as informações de localização da demanda?
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setLocalConfirmado(false)}>Cancelar</Button>
            <Button
              onPress={() => {
                setLocalConfirmado(false);
                if (estaNoLocal === "SIM") {
                  onSubmit({ estaNoLocal });
                } else {
                  onSubmit({ ...dados, estaNoLocal });
                }
              }}
            >
              Sim
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <LogoView titulo="CADASTRO DO LOCAL" />
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.3}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <ScrollView>
          <View style={{ margin: 5 }}>
            <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
              Você está no local da denúncia? *
            </CustomText>
          </View>
          {[
            { id: "SIM", nome: "Sim" },
            { id: "NAO", nome: "Não" },
          ]?.map((c, index) => {
            return (
              <Pressable
                key={"local-" + index}
                onPress={
                  estaNoLocal === c.id
                    ? () => setEstaNoLocal(null)
                    : () => {
                        if (c.id === "SIM") getLocalizacaoAtual();
                        setEstaNoLocal(c.id);
                      }
                }
                style={
                  (Styles.containerCard,
                  {
                    backgroundColor: estaNoLocal === c.id ? TUNDORA : WHITE,
                    margin: 5,
                    padding: 10,
                    borderRadius: 15,
                    textAlign: "center",
                  })
                }
              >
                <CustomText
                  tamanhoFonte={15}
                  customColor={estaNoLocal === c.id ? WHITE : BLACK}
                >
                  {c.nome}
                </CustomText>
              </Pressable>
            );
          })}
          {estaNoLocal === "SIM" &&
            localDescrito?.logradouro?.toString()?.length > 0 && (
              <>
                <CardGenerator
                  alinhamentoTexto="center"
                  tipo={"TEXTO_APENAS"}
                  texto={
                    <>
                      <CustomText
                        style={{ margin: 5 }}
                        tamanhoFonte={16}
                        customColor={BLACK}
                        isBold
                      >
                        {`LOCALIZAÇÃO ATUAL`}
                      </CustomText>
                      <CustomText
                        style={{ margin: 5, textAlign: "left" }}
                        tamanhoFonte={16}
                        customColor={BLACK}
                      >
                        {`
                  \nLogradouro: ${localDescrito?.logradouro}
                  \nBairro: ${localDescrito?.bairro || "---"}
                  \nCEP: ${localDescrito?.cep}
                  \nNumero: ${localDescrito?.numero || "S/N"}
                  `}
                      </CustomText>
                    </>
                  }
                />
              </>
            )}
          {estaNoLocal === "NAO" && (
            <>
              <Input
                control={control}
                name="logradouro"
                label="Qual o endereço? *"
                rules={{ required: true }}
                error={errors?.logradouro === undefined ? "NAO" : "SIM"}
                returnKeyType="next"
              />
              <Input
                control={control}
                name="numero"
                label="Qual o número do endereço? *"
                rules={{ required: true }}
                error={errors?.numero === undefined ? "NAO" : "SIM"}
                returnKeyType="next"
              />
              {bairros?.length > 0 ? (
                <>
                  <CustomText
                    style={{ marginTop: 20, marginLeft: 5 }}
                    tamanhoFonte={15}
                    customColor={WHITE}
                    isBold
                  >
                    Em qual bairro? *
                  </CustomText>
                  <View style={{ borderColor: "#fff", borderBottomWidth: 1 }}>
                    <Picker
                      selectedValue={dados?.bairro}
                      onValueChange={(itemValue) => setBairro(itemValue)}
                      style={{
                        color: WHITE,
                        borderColor: WHITE,
                        borderWidth: 2,
                        padding: 5,
                      }}
                    >
                      <Picker.Item
                        color="#000"
                        key={`SELECAO`}
                        label="SELECIONE"
                        value=""
                      />
                      {bairros?.map((bairro, index) => {
                        return (
                          <Picker.Item
                            color="#000"
                            key={`${bairro.nome}_${index}`}
                            label={bairro.nome}
                            value={bairro.nome}
                          />
                        );
                      })}
                    </Picker>
                  </View>
                </>
              ) : (
                <Input
                  control={control}
                  name="bairro"
                  label="Em qual bairro? *"
                  rules={{ required: true }}
                  error={errors?.bairro === undefined ? "NAO" : "SIM"}
                  returnKeyType="done"
                  onSubmitEditing={() => acaoBotao()}
                />
              )}
              <Input
                control={control}
                name="complemento"
                label="Complemento do endereço?"
                rules={{ required: false }}
                error={errors?.complemento === undefined ? "NAO" : "SIM"}
                returnKeyType="done"
                onSubmitEditing={() => acaoBotao()}
              />
            </>
          )}
        </ScrollView>
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="PROXIMO" acaoBotao={() => acaoBotao()} />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
}

export default Localizacao;
