import React from "react";
import { View, ScrollView } from "react-native";
import LogoView from "../../components/visualizacao/LogoView";
import { Styles } from "../../styles/Styles";
import { BLACK, WHITE } from "../../utils/cores";
import CardGenerator from "../../components/card/CardGenerator";
import {
  dadoExiste,
  listaServicoComunitario,
  tiposAutuados,
} from "../../utils";
import CustomText from "../../components/text/CustomText";
import { useState } from "react";
import { TextInput, ToggleButton } from "react-native-paper";
import { useForm } from "react-hook-form";

function InformativoDescontos({}) {
  const [tipoAutuado, setTipoAutuado] = useState(null);
  const [valorTotalTrabalhado, setValorTotalTrabalhado] = useState("");

  const {
    formState: {},
  } = useForm();

  const propsCustomText = { tamanhoFonte: 16 };
  const estiloEmLinhaHorizontal = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  };

  const TextoPadrao = ({ children }) => {
    return <CustomText {...propsCustomText}>{children || ""}</CustomText>;
  };

  const habilitarSeSelecionado = (tipo) =>
    dadoExiste(tipoAutuado) &&
    ((Array.isArray(tipo) && tipo.includes(tipoAutuado)) ||
      tipo === tipoAutuado);

  const calcularHorasAPartirDoResultado = (resultado) => {
    if (!/^\d+$/.test(resultado)) {
      return "Por favor, informe apenas números!";
    }

    let numeroResultado = parseInt(resultado, 10);

    const podeMostrarResultado = numeroResultado?.toString()?.length > 6;
    if (podeMostrarResultado) return "Valor inválido!";

    let horasTrabalhadas = 0;

    listaServicoComunitario.some((intervalo) => {
      const valorIntervalo = intervalo.horaFinal
        ? (intervalo.horaFinal - intervalo.horaInicial + 1) * intervalo.valor
        : intervalo.valor;

      if (numeroResultado >= valorIntervalo) {
        horasTrabalhadas +=
          intervalo.horaFinal || intervalo.horaFinal === 0
            ? intervalo.horaFinal - intervalo.horaInicial + 1
            : Math.ceil(numeroResultado / intervalo.valor);
        numeroResultado -= valorIntervalo;
      } else {
        horasTrabalhadas += Math.ceil(numeroResultado / intervalo.valor);

        return true;
      }

      return false;
    });

    return ` ${horasTrabalhadas} horas!`;
  };

  const formularioInformativoDescontos = () => {
    return (
      <div>
        <h3 style={{ textAlign: "center" }}>
          SABIA QUE É POSSÍVEL REDUZIR O VALOR DA MULTA?
        </h3>
        <h4 style={{ textAlign: "center" }}>
          O Código da Cidade (Lei Complementar nº 270/2019) oferece várias
          oportunidades para o autuado reduzir o valor da multa, com descontos
          de 10%, 15%, 25%, 50% e até 100%!
        </h4>
        <TextoPadrao>
          Marque a alternativa que corresponde ao autuado e saiba quais serão as
          suas opções:
        </TextoPadrao>
        <TextoPadrao>
          <br />
          <br />
        </TextoPadrao>
        <ToggleButton.Group
          onValueChange={(value) => {
            if (tipoAutuado === value) {
              setTipoAutuado(null);
              return null;
            }

            setTipoAutuado(value);
          }}
          value={tipoAutuado}
          blurOnSubmit={true}
        >
          {tiposAutuados?.map((tipo, index) => (
            <View
              key={`tipo_autuado_${index}`}
              style={{ ...estiloEmLinhaHorizontal, marginBottom: 20 }}
            >
              <ToggleButton
                icon={
                  habilitarSeSelecionado(tipo?.valor)
                    ? "circle"
                    : "circle-outline"
                }
                value={tipo?.valor}
              />
              <TextoPadrao>{tipo?.descricao}</TextoPadrao>
            </View>
          ))}
        </ToggleButton.Group>
        <br />
        {habilitarSeSelecionado(1) && (
          <TextoPadrao>
            O valor da multa pode ser <strong>recalculado</strong> e reduzido de
            acordo com a capacidade econômica da pessoa física inscrita no
            Cadastro Único. Para obter este benefício, entregue cópias dos
            seguintes documentos:
            <br />
            <br />
            - Documento de identificação contendo foto e data de nascimento
            <br />
            - CPF
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(2) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da pessoa física não declarante do imposto de
            renda. Para obter este benefício, entregue cópias dos seguintes
            documentos:
            <br />
            <br />
            - Documento de identificação contendo foto, data de nascimento e
            nome da mãe
            <br />
            - CPF
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(3) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da pessoa física declarante do imposto de
            renda. Entregue cópias dos seguintes documentos que comprovam a
            renda da pessoa física para recalcular o valor da multa com redução
            de até 85%:
            <br />
            <br />
            - Documento de identificação contendo foto e data de nascimento
            <br />
            - CPF
            <br />
            - Recibo da entrega à Receita Federal da Declaração de Ajuste Anual
            mais recente, contendo o Total de Rendimentos Tributáveis
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(4) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica do condomínio. Entregue cópias dos seguintes
            documentos que comprovam a receita bruta do condomínio para
            recalcular o valor da multa com redução de até 90%:
            <br />
            <br />
            - Documento de identificação do síndico (com foto)
            <br />
            - Última ata registrada contendo o valor das quotas condominiais
            mensais
            <br />
            - Declaração assinada pelo síndico e pelo contador contendo a
            receita (arrecadação) dos últimos 12 meses
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(5) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da Fundação, Associação, partido político ou
            outra entidade sem fins lucrativos. Entregue cópias dos seguintes
            documentos que comprovam a receita bruta da entidade para recalcular
            o valor da multa com redução de até 90%:
            <br />
            <br />
            - Ato constitutivo (Lei de criação, registro no Cartório ou na Junta
            Comercial)
            <br />
            - Última ata registrada
            <br />
            - Escrituração Contábil Fiscal (ECF), acompanhada do comprovante de
            transmissão (informe-se com o seu contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(6) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da Igreja ou do templo religioso. Entregue
            cópias dos seguintes documentos que comprovam a receita bruta da
            entidade para recalcular o valor da multa com redução de até 95%:
            <br />
            <br />
            - Ato constitutivo (Lei de criação, registro no Cartório ou na Junta
            Comercial)
            <br />
            - Última ata registrada
            <br />
            - Escrituração Contábil Fiscal (ECF), acompanhada do comprovante de
            transmissão (informe-se com o seu contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(7) && (
          <TextoPadrao>
            O valor da multa cobrada da Administração Pública Direta é
            específico, diferente do valor cobrado de empresas e outras
            entidades. Entregue cópia do seguinte documento, para adequação do
            valor:
            <br />
            <br />
            - Comprovante de Inscrição e Situação Cadastral (CNPJ)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(8) && (
          <TextoPadrao>
            O valor da multa para o MEI é menor do que o valor cobrado de
            empresas. Comprovar o enquadramento MEI, entregue cópias dos
            seguintes documentos:
            <br />
            <br />
            - Documento de identificação contendo foto e data de nascimento
            <br />
            - CPF
            <br />
            - CNPJ MEI
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(9) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da Microempresa (ME). Entregue cópias dos
            seguintes documentos que comprovam o faturamento da ME para
            recalcular o valor da multa com redução de até 75%:
            <br />
            <br />
            - Contrato Social
            <br />
            - Comprovante de porte da empresa (CNPJ)
            <br />
            - Demonstração do Resultado do Exercício (DRE), acompanhada do
            registro na Junta Comercial ou do comprovante de transmissão via
            Escrituração Contábil Digital (ECD) (informe-se com o seu contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(10) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da Empresa de Pequeno Porte (EPP). Entregue
            cópias dos seguintes documentos que comprovam o faturamento da EPP
            para recalcular o valor da multa com redução de até 75%:
            <br />
            <br />
            - Contrato Social
            <br />
            - Comprovante de porte da empresa (CNPJ)
            <br />
            - Demonstração do Resultado do Exercício (DRE), acompanhada do
            registro na Junta Comercial ou do comprovante de transmissão via
            Escrituração Contábil Digital (ECD) (informe-se com o seu contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(11) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da Sociedade Anônima, da Empresa Pública, ou da
            Sociedade de Economia Mista. Entregue cópias dos seguintes
            documentos que comprovam o faturamento da EPP para recalcular o
            valor da multa com redução de até 50%:
            <br />
            <br />
            - Contrato social ou ato constitutivo
            <br />
            - Comprovante de porte da empresa (CNPJ)
            <br />
            - Demonstração do Resultado do Exercício (DRE), acompanhada do
            registro na Junta Comercial ou do comprovante de transmissão via
            Escrituração Contábil Digital (ECD) (informe-se com o seu contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(12) && (
          <TextoPadrao>
            O valor da multa pode ser recalculado e reduzido de acordo com a
            capacidade econômica da entidade empresarial. Entregue cópias dos
            seguintes documentos que comprovam o faturamento da entidade
            empresarial para recalcular o valor da multa com redução de até 50%:
            <br />
            <br />
            - Contrato social ou ato constitutivo
            <br />
            - Comprovante de porte da empresa
            <br />
            - DRE (informe-se com o contador)
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado([3, 4, 5, 6, 9, 10, 11, 12]) && (
          <TextoPadrao>
            Além da redução pela comprovação da capacidade econômica do autuado,
            o valor da multa ainda poderá receber também mais um dos seguintes{" "}
            <strong>descontos</strong>:
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado([1, 2, 7, 8]) && (
          <TextoPadrao>
            O valor da multa poderá receber um dos seguintes{" "}
            <strong>descontos</strong>:
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) && (
          <TextoPadrao>
            - Desconto de 50%, se o autuado comprovar a regularização da
            situação infracional, antes do final do processo administrativo na
            Agefis (trânsito em julgado).
            <br />
            - Desconto de 20%, se até o final do prazo de defesa o autuado
            confessar a prática da infração e reconhecer a procedência do auto
            de infração.
            <br />
            - Desconto de 15%, se até o final do prazo de recurso o autuado
            confessar a prática da infração e reconhecer a procedência do auto
            de infração.
            <br />
            - Desconto de 10%, se antes do cadastro para inscrição na Dívida
            Ativa o autuado confessar a prática da infração, reconhecer a
            procedência do auto de infração e pagar o valor devido.
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado([1, 2, 3, 8]) && (
          <>
            <TextoPadrao>
              As pessoas físicas e os microempreendedores individuais poderão
              ainda fazer a <strong>comutação</strong> da penalidade de multa em
              serviços comunitários vinculados ao Programa de Voluntariado da
              Prefeitura Municipal de Fortaleza, coordenado pela Coordenadoria
              de Articulação do Terceiro Setor e Instituições Religiosas
              (Coatir). Cada hora de serviço comunitário descontará de R$ 15,00
              a R$ 50,00 no valor da multa, faça aqui uma simulação:
              <br />
              <br />
            </TextoPadrao>
            <TextoPadrao>
              Indique o valor da multa após todos os descontos:
              <br />
            </TextoPadrao>
            <View style={estiloEmLinhaHorizontal}>
              <TextoPadrao>R$</TextoPadrao>
              <TextInput
                underlineColor="transparent"
                style={{
                  backgroundColor: WHITE,
                  borderBottomColor: BLACK,
                  borderBottomWidth: 1,
                  height: 30,
                  width: 80,
                  fontSize: propsCustomText?.tamanhoFonte || 16,
                }}
                value={valorTotalTrabalhado}
                onChangeText={(text) => setValorTotalTrabalhado(text)}
              />
            </View>
            <TextoPadrao>
              Número de horas necessárias para quitar o débito:{" "}
            </TextoPadrao>
            {calcularHorasAPartirDoResultado(valorTotalTrabalhado || 0)}
            <br />
            <br />
          </>
        )}
        {habilitarSeSelecionado([1, 2, 8]) && (
          <TextoPadrao>
            Para requerer a redução da multa com os descontos e/ou a comutação
            da multa, leve a documentação indicada até a Agefis Sede, na Rua
            Francisco José de Alburquerque Pereira, 1020 - das 08:30 às 11:30h e
            das 13:30h às 16:30h.
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(3) && (
          <TextoPadrao>
            Para requerer a redução da multa com o recálculo do valor de acordo
            com a capacidade econômica do autuado, com os descontos e/ou a
            comutação da multa, leve a documentação indicada até a Agefis Sede,
            na Rua Francisco José de Alburquerque Pereira, 1020 - das 08:30 às
            11:30h e das 13:30h às 16:30h.
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado(7) && (
          <TextoPadrao>
            Para requerer a adequação do valor da multa, com os descontos, leve
            a documentação indicada até a Agefis Sede, na Rua Francisco José de
            Alburquerque Pereira, 1020 - das 08:30 às 11:30h e das 13:30h às
            16:30h.
            <br />
            <br />
          </TextoPadrao>
        )}
        {habilitarSeSelecionado([4, 5, 6, 9, 10, 11, 12]) && (
          <TextoPadrao>
            Para requerer a redução da multa com o recálculo do valor de acordo
            com a capacidade econômica do autuado, com os descontos, leve a
            documentação indicada até a Agefis Sede, na Rua Francisco José de
            Alburquerque Pereira, 1020 - das 08:30 às 11:30h e das 13:30h às
            16:30h.
            <br />
            <br />
          </TextoPadrao>
        )}
      </div>
    );
  };

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="AGÊNCIA DE FISCALIZAÇÃO DE FORTALEZA" />
      </View>
      <ScrollView style={Styles.larguraWeb}>
        <CardGenerator
          semEspacoEntreCards
          style={{ alignSelf: "center" }}
          tipo={"TEXTO_APENAS"}
          corTexto={BLACK}
          tamanhoTexto={15}
          texto={formularioInformativoDescontos()}
        />
      </ScrollView>
    </View>
  );
}

export default InformativoDescontos;
