import { errorHandler, toastr } from "../utils/messages";
import store from "../redux/store";
import * as NavigationService from "../utils/navigationService";

import axiosClient from "../utils/axiosClient";

export function cadastrarDenuncia(params) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .post("/totem/demandas", params)
      .then((resp) => {
        store.dispatch({ type: "loading/loadingStop" });
        toastr.success("Dados gravados com sucesso!");
        NavigationService.navigate("DetalheMinhasDenuncias", {
          protocoloDenuncia: resp.data?.protocolo,
          denunciaId: resp.data?.id,
          vemDoCadastro: true,
        });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export async function setArquivo(file, denunciaId, protocoloDenuncia) {
  let formData = new FormData();
  formData.append("file", {
    uri: file.uri,
    name: "-t_denunciante_t-" + file.name,
    type: "image/" + file.name.split(".").reverse()[0],
  });
  formData.append("id", denunciaId);
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    await axiosClient
      .post("/cidadaos/enviar-arquivo", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async () => {
        toastr.success("Arquivo gravado com sucesso!");
        await axiosClient
          .get(`/totem/demandas/protocolo/${protocoloDenuncia}`)
          .then((resp) => {
            store.dispatch({
              type: "denuncias/DENUNCIAS_DETAIL",
              payload: resp.data,
            });
          })
          .catch(() => {
            toastr.error("Falha ao atualizar a lista de arquivos!");
          }),
          store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export async function removerArquivo(id, filename, protocoloDenuncia) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    await axiosClient
      .delete(`/cidadaos/remover-arquivo?id=${id}&filename=${filename}`)
      .then(async () => {
        toastr.success("Arquivo excluído com sucesso!");
        await axiosClient
          .get(`/totem/demandas/protocolo/${protocoloDenuncia}`)
          .then((resp) => {
            store.dispatch({
              type: "denuncias/DENUNCIAS_DETAIL",
              payload: resp.data,
            });
          })
          .catch(() => {
            toastr.error("Falha ao atualizar a lista de arquivos!");
          }),
          store.dispatch({ type: "loading/loadingStop" });
      })
      .catch(() => {
        toastr.error("Falha ao excluir o arquivo, tente novamente mais tarde!");
        store.dispatch({ type: "loading/loadingStop" });
      }),
  ];
}

export function geoService(logradouro, numero, bairro) {
  store.dispatch({ type: "loading/loadingStart" });
  const url = `geo?logradouro=${logradouro}&numero=${numero}&bairro=${bairro}`;
  axiosClient
    .get(url)
    .then((resp) => {
      store.dispatch({
        type: "cadastroDenuncia/localizacaoSent",
        payload: resp.data,
      });
      store.dispatch({ type: "loading/loadingStop" });
      NavigationService.navigate("CadastroTurnosDiasFuncionamento");
    })
    .catch((e) => {
      store.dispatch({ type: "loading/loadingStop" });
      errorHandler(e);
    });
}

export const getLocalAtual = async (latlng) => {
  const url = `/geo/localPorLatLong?latlng=${latlng}`;

  await axiosClient
    .get(url)
    .then((resp) =>
      store.dispatch({
        type: "cadastroDenuncia/localizacaoSent",
        payload: resp.data,
      })
    )
    .catch((e) => errorHandler(e));
};

export function getMinhasDenuncias(idUsuarioLogado) {
  store.dispatch({ type: "loading/loadingStart" });
  const url = `/cidadaos/${idUsuarioLogado}/minhas-denuncias`;
  axiosClient
    .get(url)
    .then((resp) => {
      store.dispatch({
        type: "denuncias/DENUNCIAS_LISTED",
        payload: resp.data,
      });
      store.dispatch({ type: "loading/loadingStop" });
    })
    .catch((e) => {
      store.dispatch({ type: "loading/loadingStop" });
      errorHandler(e);
    });
}

export function getDetalheDemanda(denunciaId) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .get(`/totem/demandas/protocolo/${denunciaId}`)
      .then((resp) => {
        store.dispatch({
          type: "denuncias/DENUNCIAS_DETAIL",
          payload: resp.data,
        });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}
