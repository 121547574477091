import { Alert } from "react-native";
import { eAplicativo } from ".";

const message = (message) => {
  Alert.alert("Mensagem", message, [{ text: "OK", onPress: () => {} }], {
    cancelable: false,
  });
};
const errorMessage = (message, title = "Ops...") => {
  Alert.alert(title, message, [{ text: "OK", onPress: () => {} }], {
    cancelable: false,
  });
};

const getMensagemAviso = (titulo, message) => {
  if (!message) return;
  if (!eAplicativo) {
    window.confirm([titulo, message]?.join("\n\n"));
    return null;
  }
  Alert.alert(titulo, message, [{ text: "OK", onPress: () => {} }], {
    cancelable: false,
  });
};

const toastr = {
  success: (message = "Dados gravados com sucesso!") => {
    getMensagemAviso("Tudo certo!", message);
  },
  error: (message = null) => getMensagemAviso("Falhou!", message),
  warning: (message = "Verifique seus dados.") => {
    getMensagemAviso("Atenção!", message);
  },
};

const errorHandler = (e) => {
  if (e.message === "Network Error") {
    toastr.error(
      "Nossos servidores estão indisponíveis no momento. Por favor, entre em contato com o suporte"
    );
  } else if (e.response) {
    const mensagemErro = e?.response?.data?.error?.toString();

    let mensagemRetorno =
      mensagemErro || "Algo deu errado, tente novamente mais tarde.";

    const entidadeComFalha = mensagemErro?.includes(
      "javax.persistence.EntityNotFoundException"
    );

    if (entidadeComFalha) {
      mensagemRetorno = "Erro na identificação desta solicitação.";
    }

    toastr.error(mensagemRetorno);
  } else {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export { message, errorMessage, toastr, errorHandler };
