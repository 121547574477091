export const [
  SUPERNOVA,
  LIGHTNING_YELLOW,
  TANGO,
  AMARANTH,
  PICTON_BLUE,
  BLUE_CHILL,
  CITRON,
  TUNDORA,
  WHITE,
  BLACK,
  DARK_BLUE,
  ATOLL,
  KEPPEL,
] = [
  "#ffcc00",
  "#FBB91C",
  "#EF771B",
  "#E62155",
  "#1EB1E7",
  "#10949E",
  "#9DA423",
  "#404040",
  "#fff",
  "#000",
  "#1b51a8",
  "#086e75",
  "#31a7b0",
];
