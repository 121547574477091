import React, { useState } from "react";
import { View, Platform, ScrollView, Pressable } from "react-native";
import { useForm } from "react-hook-form";
import { ProgressBar, Colors } from "react-native-paper";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import CustomButton from "../../../components/buttons/CustomButton";
import { BLACK, TUNDORA, WHITE } from "../../../utils/cores";
import { toastr } from "../../../utils/messages";
import {
  dataHoraAndroid,
  dataHoraIos,
  dataHoraWeb,
} from "../../../components/form/InputDataHora";

function DataHoraDenuncia({ navigation }) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date(Date.now()));
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [denunciaOcorreuAgora, setDenunciaOcorreuAgora] = useState(null);

  const onChange = (selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === "ios");
    setDate(currentDate);
  };

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => showMode("date");
  const showTimepicker = () => showMode("time");

  const { handleSubmit } = useForm();
  const onSubmit = (data) => {
    let dataDenuncia = data;
    const naoPressionado = data?.id === null || data?.id === undefined;
    if (naoPressionado) {
      if (denunciaOcorreuAgora !== null) {
      } else {
        toastr.warning(
          "Selecione uma opção, se a denúncia está ou não ocorrendo agora!"
        );
        return null;
      }
      dataDenuncia = date;
    } else {
      if (data?.id === "NAO") {
        setDenunciaOcorreuAgora("NAO");
        return null;
      }
      setDenunciaOcorreuAgora("SIM");
      dataDenuncia = new Date();
    }
    dispatch({
      type: "cadastroDenuncia/dataHoraDenunciaSent",
      payload: {
        dataHoraDenuncia: format(dataDenuncia, "dd/MM/yyyy HH:mm:ss"),
      },
    });
    navigation.navigate("DescricaoDenuncia");
  };

  return (
    <ScrollView style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CADASTRO DA DATA E HORA" />
      </View>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.7}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <View style={{ margin: 5 }}>
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            O evento denunciado está ocorrendo neste momento? *
          </CustomText>
        </View>
        {[
          { id: "SIM", nome: "Sim" },
          { id: "NAO", nome: "Não" },
        ]?.map((c, index) => {
          return (
            <Pressable
              key={"ocorrendo-agora-" + index}
              onPress={() => handleSubmit(onSubmit({ id: c.id }))}
              style={
                (Styles.containerCard,
                {
                  backgroundColor:
                    denunciaOcorreuAgora === c.id ? TUNDORA : WHITE,
                  margin: 5,
                  padding: 10,
                  borderRadius: 15,
                  textAlign: "center",
                })
              }
            >
              <CustomText
                tamanhoFonte={15}
                customColor={denunciaOcorreuAgora === c.id ? WHITE : BLACK}
              >
                {c.nome}
              </CustomText>
            </Pressable>
          );
        })}
        {denunciaOcorreuAgora === "NAO"
          ? Platform.OS === "ios"
            ? dataHoraIos({ onChange, date })
            : Platform.OS === "android"
            ? dataHoraAndroid({
                show,
                showDatepicker,
                showTimepicker,
                onChange,
                date,
                mode,
              })
            : dataHoraWeb({ onChange, date })
          : null}
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="PROXIMO" acaoBotao={handleSubmit(onSubmit)} />
        </View>
      </View>
    </ScrollView>
  );
}

export default DataHoraDenuncia;
