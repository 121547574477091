import React from "react";
import { View, Image } from "react-native";
import CustomText from "../text/CustomText";
import Logo from "../../../assets/logo-pmf.png";
import LogoAgefis from "../../../assets/agefis-logo.png";
import { Styles } from "../../styles/Styles";
import { WHITE } from "../../utils/cores";

function LogoView(props = {}) {
  const { comLogoAgefis = false } = props;

  return (
    <View style={Styles.containerLogo}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        {comLogoAgefis ? (
          <Image
            source={LogoAgefis}
            resizeMode="contain"
            style={Styles.imagem}
          />
        ) : (
          <Image source={Logo} resizeMode="contain" style={Styles.imagem} />
        )}
      </View>
      <CustomText
        style={{ textAlign: "center" }}
        tamanhoFonte={20}
        customColor={WHITE}
        isBold
      >
        {props.titulo || "FISCALIZE FORTALEZA"}
      </CustomText>
    </View>
  );
}

export default LogoView;
