import React, { useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { ProgressBar, Colors } from "react-native-paper";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toastr } from "../../utils/messages";

import CustomText from "../../components/text/CustomText";
import { GRUPOS_FISCALIZACAO } from "../../utils/gruposFiscalizacao";
import LogoView from "../../components/visualizacao/LogoView";
import { Styles } from "../../styles/Styles";
import CustomButton from "../../components/buttons/CustomButton";
import { BLACK, TUNDORA, WHITE } from "../../utils/cores";

function CadastrarDenuncia({ navigation }) {
  const dispatch = useDispatch();
  const [grupoSelecionado, setGrupoSelecionado] = useState(null);

  const onSubmit = (grupo) => {
    if (grupo?.id !== undefined) {
      setGrupoSelecionado(grupo.id);
      dispatch({
        type: "cadastroDenuncia/grupoDenunciaSelected",
        payload: { grupoSelecionado: grupo.id },
      });
      navigation.navigate("Step2Fiscalizado");
    } else {
      if (grupoSelecionado === undefined || grupoSelecionado === null) {
        toastr.warning("Toque em um tipo de denúncia!");
        return null;
      } else {
        dispatch({
          type: "cadastroDenuncia/grupoDenunciaSelected",
          payload: { grupoSelecionado },
        });
        navigation.navigate("Step2Fiscalizado");
      }
    }
  };

  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const {
    handleSubmit,
    formState: {},
  } = useForm();

  return (
    <ScrollView style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CADASTRO DO TIPO FISCALIZADO" />
      </View>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.0}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <View style={{ margin: 5 }}>
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            Qual fiscalização você deseja solicitar? *
          </CustomText>
        </View>
        {GRUPOS_FISCALIZACAO?.sort((a, b) => {
          if (a.nome > b.nome) return 1;
          if (a.nome < b.nome) return -1;
          return 0;
        })?.map((g, index) => {
          return (
            <Pressable
              key={"grupo-" + index}
              onPress={() => handleSubmit(onSubmit(g))}
              style={
                (Styles.containerCard,
                {
                  backgroundColor: grupoSelecionado === g.id ? TUNDORA : WHITE,
                  margin: 5,
                  padding: 10,
                  borderRadius: 15,
                  textAlign: "center",
                })
              }
            >
              <CustomText
                tamanhoFonte={15}
                customColor={grupoSelecionado === g.id ? WHITE : BLACK}
              >
                {g?.nome?.split(" ")?.map(capitalize)?.join(" ") ||
                  "Grupo Não encotrado"}
              </CustomText>
            </Pressable>
          );
        })}
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="PROXIMO" acaoBotao={handleSubmit(onSubmit)} />
        </View>
      </View>
    </ScrollView>
  );
}

export default CadastrarDenuncia;
