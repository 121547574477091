import { createSlice } from "@reduxjs/toolkit";

export const AutoReducer = createSlice({
  name: "auto",
  initialState: {
    list: null,
    detail: null,
  },
  reducers: {
    AUTO_LISTED: (state, action) => {
      state.list = action.payload;
    },
    AUTO_DETAIL: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const {} = AutoReducer.actions;
export default AutoReducer.reducer;
