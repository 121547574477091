import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";

import { login } from "../../api/auth";
import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import Copyright from "../../components/visualizacao/Copyright";
import CustomButton from "../../components/buttons/CustomButton";
import { Input } from "../../components/form/Input";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { eAplicativo, irParaFortalezaDigital } from "../../utils";

const Login = ({ navigation }) => {
  const [verSenha, setVerSenha] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => login(data);

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      scrollIndicatorInsets={{ right: 1 }}
      style={Styles.containerPrincipal}
      contentContainerStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={Styles.larguraWeb}>
        <View style={{ marginTop: 20 }}>
          <LogoView titulo="FISCALIZE FORTALEZA" />
        </View>
        <Input
          control={control}
          name="email"
          label="Email"
          rules={{ required: true }}
          autoCapitalize="none"
          keyboardType="email-address"
          error={errors?.email === undefined ? "NAO" : "SIM"}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <Input
          control={control}
          name="senha"
          label="Senha"
          rules={{ required: true }}
          error={errors?.email === undefined ? "NAO" : "SIM"}
          secureTextEntry={verSenha ? false : true}
          verSenha={verSenha}
          mudarVerSenha={() => setVerSenha(!verSenha)}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <View style={Styles.containerBotoesDuplos}>
          <CustomButton
            tipo="CADASTRAR"
            acaoBotao={() =>
              eAplicativo
                ? navigation.navigate("SignUp")
                : irParaFortalezaDigital()
            }
          />
          <CustomButton tipo="ACESSAR" acaoBotao={handleSubmit(onSubmit)} />
        </View>
        <View style={Styles.containerBotoesDuplos}>
          <CustomButton
            tipo="RECUPERAR_SENHA"
            acaoBotao={() => navigation.navigate("ResetPassword")}
          />
        </View>
        {!eAplicativo && (
          <View style={Styles.containerBotoesDuplos}>
            <CustomButton
              tipo="AJUSTAVEL"
              tituloBotao="Login Fortaleza Digital"
              acaoBotao={() => irParaFortalezaDigital()}
            />
          </View>
        )}
        <Copyright />
      </View>
    </KeyboardAwareScrollView>
  );
};

export default Login;
