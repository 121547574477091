import React, { useEffect } from "react";
import { View } from "react-native";
import { useForm } from "react-hook-form";
import { ProgressBar, Colors } from "react-native-paper";
import { toastr } from "../../../utils/messages";
import { useDispatch } from "react-redux";
import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import CustomButton from "../../../components/buttons/CustomButton";
import { Input } from "../../../components/form/Input";
import { WHITE } from "../../../utils/cores";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function DescricaoDenuncia({ navigation }) {
  const dispatch = useDispatch();

  useEffect(() => {
    toastr.warning(
      "É importante que sejam informados os detalhes sobre a ocorrência para que a fiscalização" +
        " seja realizada com maior efetividade"
    );
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const descricao = data?.descricaoDenuncia?.replace(/\n|\r/g, "");
    const dados = { descricaoDenuncia: descricao };
    if (descricao === undefined || descricao === "") {
      toastr.warning(
        "Digite uma descrição para que possamos realizar a fiscalização da denúncia!"
      );
      return null;
    } else {
      dispatch({
        type: "cadastroDenuncia/descricaoDenunciaSent",
        payload: dados,
      });
      navigation.navigate("Resumo");
    }
  };

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      style={Styles.containerPrincipal}
    >
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CADASTRO DA DESCRIÇÃO" />
      </View>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.9}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <View style={{ margin: 5 }}>
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            Descreva a ocorrência: *
          </CustomText>
        </View>
        <Input
          control={control}
          name="descricaoDenuncia"
          label="Descrição"
          rules={{ required: true }}
          error={errors?.descricaoDenuncia === undefined ? "NAO" : "SIM"}
          multiline
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="PROXIMO" acaoBotao={handleSubmit(onSubmit)} />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
}

export default DescricaoDenuncia;
