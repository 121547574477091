import React from "react";
import { WHITE } from "../../utils/cores";
import { voltarTela } from "../../utils/navigationService";
import { FAB } from "react-native-paper";

function BotaoVoltar(props) {
  const { acao, aDireita, semEspacoTopo, estiloBotao = {} } = props;
  const acaoVoltar = () => (props?.acao ? acao() : voltarTela());

  const estiloParaLados = aDireita === true ? { right: 10 } : { left: 10 };
  const estiloEspacaoParaCima = semEspacoTopo === true ? {} : { top: 50 };

  return (
    <>
      <FAB
        style={{
          backgroundColor: WHITE,
          position: "absolute",
          ...estiloParaLados,
          ...estiloEspacaoParaCima,
          ...estiloBotao,
        }}
        small
        icon="arrow-left"
        onPress={() => acaoVoltar()}
      />
    </>
  );
}

export default BotaoVoltar;
