import React, { useState } from "react";
import { View, ScrollView } from "react-native";

import LogoView from "../../components/visualizacao/LogoView";
import { Styles } from "../../styles/Styles";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";
import { BLACK } from "../../utils/cores";
import CustomButton from "../../components/buttons/CustomButton";
import { AMARANTH } from "../../utils/cores";
import { WHITE } from "../../utils/cores";
import { useSelector } from "react-redux";
import { TEXTO_DESATIVACAO_CONTA, dadoExiste } from "../../utils";
import { desativarConta } from "../../api/auth";
import { Input } from "../../components/form/Input";
import { useForm } from "react-hook-form";
import CardGenerator from "../../components/card/CardGenerator";

function DesativacaoConta({}) {
  const idUsuarioLogado = useSelector((state) => state?.auth?.user?.id);

  const [confirmadoDesativacao, setConfirmadoDesativacao] = useState(false);
  const [verSenha, setVerSenha] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) =>
    confirmadoDesativacao
      ? desativarConta(idUsuarioLogado, data?.senha)
      : setConfirmadoDesativacao(true);

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="DESATIVAÇÃO DA CONTA" />
      </View>
      <BotaoVoltar />
      <ScrollView style={Styles.larguraWeb}>
        <CardGenerator
          semEspacoEntreCards
          style={{ alignSelf: "center" }}
          tipo={"TEXTO_APENAS"}
          corTexto={BLACK}
          tamanhoTexto={15}
          texto={TEXTO_DESATIVACAO_CONTA}
        />
      </ScrollView>
      <View style={Styles.larguraWeb}>
        {confirmadoDesativacao && (
          <Input
            control={control}
            name="senha"
            label="Senha"
            rules={{ required: true }}
            error={dadoExiste(errors?.senha) ? "SIM" : "NAO"}
            secureTextEntry={verSenha ? false : true}
            verSenha={verSenha}
            mudarVerSenha={() => setVerSenha(!verSenha)}
            returnKeyType="done"
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        <CustomButton
          tipo="AJUSTAVEL"
          corBotao={confirmadoDesativacao ? AMARANTH : WHITE}
          corTextoBotao={confirmadoDesativacao ? WHITE : BLACK}
          tituloBotao={
            confirmadoDesativacao ? "DESATIVAR CONTA" : "TEM CERTEZA DISTO?"
          }
          acaoBotao={handleSubmit(onSubmit)}
        />
      </View>
    </View>
  );
}

export default DesativacaoConta;
