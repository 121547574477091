import axios from "axios";
import store from "../redux/store";

import authHeader from "./authHeader";
import consts from "../config";

const axiosClient = axios.create({
  baseURL: consts.API_URL,
  headers: authHeader(),
});

axiosClient.interceptors.request.use(function (config) {
  const token = store.getState().auth?.token;
  config.headers.Authorization = token ? token : "";
  return config;
});

export default axiosClient;
