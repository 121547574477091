import React from "react";
import { Text } from "react-native";
import {
  useFonts,
  OpenSans_400Regular,
  OpenSans_700Bold,
} from "@expo-google-fonts/open-sans";
import { BLACK } from "../../utils/cores";

function CustomText(props) {
  let [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_700Bold,
  });

  if (!fontsLoaded) return null;
  return (
    <Text
      style={{
        ...props.style,
        fontFamily: props.isBold ? "OpenSans_700Bold" : "OpenSans_400Regular",
        fontSize: props.tamanhoFonte ? props.tamanhoFonte : 14,
        color: props.customColor ? props.customColor : BLACK,
      }}
    >
      {props.children}
    </Text>
  );
}

export default CustomText;
