export default {
  USER_KEY: "_fiscalize_fortaleza_user",
  TELA_INICIAL_KEY: "_TELA_INICIAL_KEY",
  SECRET: "FISCALIZE-FORTALEZA-@@-AGEFIS-@@-DIALOG",
  androidClientId:
    "1031823644869-q270o7nkaflddl4rj6e9di8rofi9ccen.apps.googleusercontent.com",
  iosClientId:
    "1031823644869-7t4dustb9oqr3mlepian98730g5522a9.apps.googleusercontent.com",
  facebookKey: "179868206004079",
  GoogleKey: "AIzaSyBDJqXEZqEN8QWrt31XdGtWv8ZkU7uG2Hk",

  // ------------- Informações Fortaleza Digital -------------
  USER_KEY: "_FISCALIZE_FORTALEZA_WEB_",
  GOOGLE_MAPS_APIKEY: "AIzaSyAJvEqu8_FJe2_sumDOhfCCAECkGybQJIM",
  REALM_NAME: "pmf",
  RHSSO_URL: "https://login-cidadao.fortaleza.ce.gov.br",
  CLIENT_ID: "denuncia-virtual-agefis",
  CLIENT_SECRET: "11058c6e-3537-49aa-b2d5-2b0b9b145083",

  // ------------- IP para as API's -------------
  API_URL: "https://api-v2.agefis.fortaleza.ce.gov.br",
  // API_URL: "http://172.30.181.6:8080/fiscalize-v2",
  // API_URL: "http://localhost:8080/fiscalize-v2",

  // ------------- IP para tela inicial front-end -------------
  URI: "https://denuncia.agefis.fortaleza.ce.gov.br",
  // URI: "https://hom-denuncia.agefis.fortaleza.ce.gov.br",
  // URI: "http://localhost:19006",
};
