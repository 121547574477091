import React from "react";
import { ScrollView, View } from "react-native";
import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import { useSelector } from "react-redux";
import { inserirPontuacaoCpfCnpj } from "../../utils";
import { getDetalheInfracao } from "../../api/autos";
import CardGenerator from "../../components/card/CardGenerator";
import { TextInput } from "react-native-paper";
import { useState } from "react";
import CustomText from "../../components/text/CustomText";
import { SUPERNOVA, TUNDORA, WHITE } from "../../utils/cores";
import CustomButton from "../../components/buttons/CustomButton";

const MinhasInfracoes = ({ navigation }) => {
  const usuarioLogado = useSelector((state) => state?.auth?.user);
  const [dados, setDados] = useState({
    cpfCnpj: usuarioLogado?.cpfCnpj || "",
    numeroDocumento: "",
    serie: "A",
  });

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo={"CONSULTAR INFRAÇÃO"} comLogoAgefis />
      </View>
      <ScrollView style={Styles.larguraWeb}>
        <CardGenerator
          semEspacoEntreCards
          alinhamentoTexto="center"
          tipo={"TEXTO_APENAS"}
          texto={
            <>
              <CustomText style={{ fontSize: 16 }}>
                Para consultar o vídeo da infração, preencha abaixo as
                informações solicitadas:
              </CustomText>
              <br />
              <br />
              <View style={{ marginTop: 0, width: 200 }}>
                <TextInput
                  label="CPF/CNPJ"
                  underlineColor="transparent"
                  style={Styles.containerInputMinhaInfracao}
                  value={inserirPontuacaoCpfCnpj(dados?.cpfCnpj)}
                  onChangeText={(text) => {
                    if (text?.length > 18) return null;
                    setDados({ ...dados, cpfCnpj: text });
                  }}
                />
                <br />
                <TextInput
                  label="Número da infração"
                  underlineColor="transparent"
                  style={Styles.containerInputMinhaInfracao}
                  value={dados?.numeroDocumento}
                  onChangeText={(text) =>
                    setDados({ ...dados, numeroDocumento: text })
                  }
                />
                <br />
                <TextInput
                  label="Série"
                  underlineColor="transparent"
                  style={Styles.containerInputMinhaInfracao}
                  value={dados?.serie}
                  onChangeText={(text) =>
                    setDados({ ...dados, serie: text?.toUpperCase() })
                  }
                />
              </View>
              <br />
              <CustomButton
                tipo="AJUSTAVEL"
                corTextoBotao={WHITE}
                corBotao={TUNDORA}
                tituloBotao={"CONSULTAR"}
                acaoBotao={() => getDetalheInfracao(dados)}
              />
              <br />
              <br />
              <CustomText style={{ fontSize: 16 }}>
                Em caso de dúvida, entre em contato com a ouvidoria da Agência
                de Fiscalização de Fortaleza (AGEFIS).
                <br />
                <br />
                <strong>Ouvidoria</strong>
                <br />
                Telefone: 3484.8032
                <br />
                Email: ouvidoria.agefis@agefis.fortaleza.ce.gov.br
              </CustomText>
            </>
          }
        />
        {/* {infracoes?.length === 0 && (
          <CardGenerator
            semEspacoEntreCards
            alinhamentoTexto="center"
            tipo={"TEXTO_APENAS"}
            texto={"Nenhum registro de infração a ser exibido"}
          />
        )} */}
        {/* <List.AccordionGroup>
          {infracoes?.map((item = {}, index) => {
            const textoNumeroDocumentoFiscal = textoNumeroDocumentoComSerie(
              item
            );

            const { fundamentacaoLegal, descricao, dataCriacao } = item;

            const textoFundamentacaoLegal =
              fundamentacaoLegal +
              (fundamentacaoLegal?.endsWith("\n") ? "" : "\n");

            return (
              <View
                key={`${index}-denuncia`}
                style={[
                  Styles.containerCard,
                  {
                    backgroundColor: index % 2 === 0 ? KEPPEL : WHITE,
                    alignSelf: "center",
                  },
                ]}
              >
                <List.Accordion
                  title={
                    <CustomText tamanhoFonte={15} customColor={BLACK} isBold>
                      {textoNumeroDocumentoFiscal}
                    </CustomText>
                  }
                  description={
                    <CustomText tamanhoFonte={15} customColor={BLACK}>
                      {`Descrição: ${encurtarTextoSeNecessario(descricao)}`}
                    </CustomText>
                  }
                  id={item?.id}
                >
                  <View style={Styles.containerLogo}>
                    <CardGenerator
                      tipo={"TEXTO_COM_BOTAO"}
                      alinhamentoTexto="left"
                      tamanhoFonte={15}
                      corCard={index % 2 === 0 ? WHITE : KEPPEL}
                      corBotao={index % 2 === 0 ? KEPPEL : WHITE}
                      acao={() =>
                        navigation.navigate("DetalheMinhaInfracao", {
                          autoId: item?.id,
                          cpfCnpj: usuarioLogado?.cpfCnpj || cpfCnpj,
                        })
                      }
                      tituloBotao={"VER DETALHES"}
                      corTexto={BLACK}
                      texto={
                        <>
                          {dadoExiste(textoNumeroDocumentoFiscal) &&
                            `Nº DOCUMENTO FISCAL:\n${textoNumeroDocumentoFiscal}`}
                          {dadoExiste(dataCriacao) &&
                            `\n\nDATA/HORA DE CRIAÇÃO:\n${dataCriacao}`}
                          {dadoExiste(fundamentacaoLegal) &&
                            `\n\nFUNDAMENTAÇÃO LEGAL:\n${textoFundamentacaoLegal}`}
                          {dadoExiste(descricao) &&
                            `\nDESCRIÇÃO:\n${encurtarTextoSeNecessario(
                              descricao
                            )}`}
                        </>
                      }
                    />
                  </View>
                </List.Accordion>
              </View>
            );
          })}
        </List.AccordionGroup> */}
      </ScrollView>
    </View>
  );
};

export default MinhasInfracoes;
