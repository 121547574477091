import React from "react";
import { View } from "react-native";
import { BLACK, DARK_BLUE, WHITE } from "../../utils/cores";
import CustomButton from "../buttons/CustomButton";
import CustomText from "../text/CustomText";
import { format } from "date-fns";
import DateTimePicker from "@react-native-community/datetimepicker";
import { eAplicativo } from "../../utils";

const getDateTimePickers = (e) => {
  let periodo = e;
  let eData = periodo?.toString()?.includes("-");
  if (eData !== true) {
    periodo = new Date(e?.nativeEvent?.timestamp);
    eData = periodo?.toString()?.includes("-");
  }
  return periodo;
};

export const dataHoraIos = (dados) => {
  const { onChange, date } = dados;
  return (
    <View style={{ margin: 10 }}>
      <CustomText
        style={{ textAlign: "center" }}
        tamanhoFonte={15}
        customColor={BLACK}
        isBold
      >
        DATA DA OCORRÊNCIA
      </CustomText>
      <DateTimePicker
        style={{ backgroundColor: DARK_BLUE, margin: 10 }}
        testID="datePicker"
        value={date}
        mode={"date"}
        is24Hour={true}
        display="default"
        onChange={onChange}
      />
      <CustomText
        style={{ textAlign: "center" }}
        tamanhoFonte={15}
        customColor={BLACK}
        isBold
      >
        HORA DA OCORRÊNCIA
      </CustomText>
      <DateTimePicker
        style={{ backgroundColor: DARK_BLUE, margin: 10 }}
        testID="TimePicker"
        value={date}
        mode={"time"}
        onChange={(e) => onChange(getDateTimePickers(e))}
      />
    </View>
  );
};

export const dataHoraAndroid = (dados) => {
  const { show, showDatepicker, showTimepicker, onChange, date, mode } = dados;
  return (
    <View style={{ display: "flex", alignSelf: "center" }}>
      <View style={{ margin: 10 }}>
        <CustomText
          style={{ textAlign: "center" }}
          tamanhoFonte={15}
          customColor={WHITE}
          isBold
        >
          DATA DA OCORRÊNCIA
        </CustomText>
        <CustomButton
          tipo="AJUSTAVEL"
          tituloBotao={format(date, "dd/MM/yyyy")}
          corBotao={WHITE}
          corTextoBotao={BLACK}
          acaoBotao={showDatepicker}
        />
      </View>
      <View style={{ margin: 10 }}>
        <CustomText
          style={{ textAlign: "center" }}
          tamanhoFonte={15}
          customColor={WHITE}
          isBold
        >
          HORA DA OCORRÊNCIA
        </CustomText>
        <CustomButton
          tipo="AJUSTAVEL"
          tituloBotao={format(date, "HH:mm")}
          corBotao={WHITE}
          corTextoBotao={BLACK}
          acaoBotao={showTimepicker}
        />
      </View>
      {show && (
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={mode}
          is24Hour={true}
          display="default"
          onChange={(e) => onChange(getDateTimePickers(e))}
        />
      )}
    </View>
  );
};

export const dataHoraWeb = (dados) => {
  const { onChange, date } = dados;
  const inputDataHoraWeb = {
    textAlign: "center",
    backgroundColor: WHITE,
    margin: 10,
    padding: 5,
    color: BLACK,
    fontWeight: "bold",
    width: eAplicativo ? "auto" : "70%",
    alignSelf: "center",
  };

  return (
    <View style={{ margin: 10 }}>
      <CustomText
        style={{ textAlign: "center" }}
        tamanhoFonte={15}
        customColor={BLACK}
        isBold
      >
        DATA DA OCORRÊNCIA
      </CustomText>
      <input
        style={inputDataHoraWeb}
        type="date"
        name="data"
        value={format(date, "yyyy-MM-dd")}
        onChange={(e) => {
          const dataSelecionada = e?.target?.value?.toString()?.split("-");
          let dataFormatada = new Date(date);
          dataFormatada.setFullYear(dataSelecionada?.[0]);
          dataFormatada.setUTCMonth(dataSelecionada?.[1] - 1);
          dataFormatada.setDate(dataSelecionada?.[2]);
          onChange(dataFormatada);
        }}
      />
      <CustomText
        style={{ textAlign: "center" }}
        tamanhoFonte={15}
        customColor={BLACK}
        isBold
      >
        HORA DA OCORRÊNCIA
      </CustomText>
      <input
        style={inputDataHoraWeb}
        onChange={(e) => {
          const dataSelecionada = e?.target?.value?.toString()?.split(":");
          let dataFormatada = new Date(date);
          dataFormatada.setHours(dataSelecionada?.[0], dataSelecionada?.[1]);
          onChange(dataFormatada);
        }}
        type="time"
        name="hora"
        value={format(date, "HH:mm")}
      />
    </View>
  );
};
