import React, { useState } from "react";
import { Modal, Pressable, ScrollView, View } from "react-native";
import { Portal } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import ModalDevs from "../../ModalDevs";
import { logout } from "../../api/auth";
import { Styles } from "../../styles/Styles";
import {
  AMARANTH,
  BLACK,
  DARK_BLUE,
  WHITE,
  LIGHTNING_YELLOW,
  BLUE_CHILL,
} from "../../utils/cores";
import BotaoDrawer from "../buttons/BotaoDrawer";
import CustomText from "../text/CustomText";
import BotaoVoltar from "../buttons/BotaoVoltar";
import { useSelector } from "react-redux";
import { obterPrimeiroNome } from "../../utils";

function CardMenuDrawer(props) {
  const usuario = useSelector((state) => state?.auth?.user);
  const [clicks, setClicks] = useState(0);
  const [sleeping, setSleeping] = useState(false);
  const [modalDevs, setModalDevs] = useState(false);
  // const drawerInfo = useRef();
  // const heightDrawer = drawerInfo?.current?.offsetHeight;
  // const widthDrawer = drawerInfo?.current?.offsetWidth;

  const nomeUsuario = obterPrimeiroNome(usuario?.nome?.toUpperCase());

  return (
    <>
      <View style={{ flex: 1, backgroundColor: BLUE_CHILL }}>
        {modalDevs && (
          <Portal>
            <Modal
              contentContainerStyle={Styles.containerModal}
              animationType="slide"
              dismissable
              visible={modalDevs}
              onDismiss={() => setModalDevs(false)}
            >
              <ModalDevs fecharModal={() => setModalDevs(false)} />
            </Modal>
          </Portal>
        )}
        <BotaoVoltar acao={() => props.navigation?.closeDrawer()} aDireita />
        <ScrollView style={{ marginTop: 90 }}>
          <BotaoDrawer
            icone={
              <FontAwesome name="info-circle" size={30} color={DARK_BLUE} />
            }
            tituloBotao="SOBRE O FISCALIZE"
            acaoBotao={() => props.navigation?.push("Sobre")}
          />
          <BotaoDrawer
            icone={
              <FontAwesome
                name="exclamation-circle"
                size={30}
                color={LIGHTNING_YELLOW}
              />
            }
            tituloBotao="DESATIVAR CONTA"
            acaoBotao={() => props.navigation?.navigate("DesativacaoConta")}
          />
          <BotaoDrawer
            icone={<FontAwesome name="print" size={30} color={DARK_BLUE} />}
            tituloBotao="POLÍTICA DE PRIVACIDADE"
            acaoBotao={() => props.navigation?.navigate("PoliticaPrivacidade")}
          />
          <BotaoDrawer
            icone={<FontAwesome name="close" size={30} color={AMARANTH} />}
            tituloBotao="DESCONECTAR"
            acaoBotao={() => logout()}
          />
        </ScrollView>
        {clicks !== 0 && sleeping && (
          <View
            style={{
              backgroundColor: WHITE,
              borderRadius: 10,
              bottom: 10,
              padding: 5,
              width: 50,
              marginBottom: 15,
              alignSelf: "center",
            }}
          >
            <CustomText
              style={{ textAlign: "center" }}
              tamanhoFonte={20}
              customColor={BLACK}
              isBold
            >
              {clicks}
            </CustomText>
          </View>
        )}
        <Pressable
          style={{ paddingBottom: 10 }}
          onPress={async () => {
            const abrirModal = clicks < 4 ? false : true;
            if (abrirModal !== true) {
              setClicks(clicks + 1);
              setSleeping(true);
              await new Promise((s) => setTimeout(s, 1000));
              setSleeping(false);
            } else {
              setModalDevs(true);
              setClicks(0);
            }
          }}
        >
          <CustomText
            style={{ bottom: 20, textAlign: "center" }}
            tamanhoFonte={20}
            customColor={WHITE}
            isBold
          >
            {`© ${new Date().getFullYear()} Prefeitura de Fortaleza, AGEFIS, DIALOG, GETEC`}
          </CustomText>
        </Pressable>
      </View>
    </>
  );
}

export default CardMenuDrawer;
