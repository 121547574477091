import store from "../redux/store";
import axiosClient from "../utils/axiosClient";
import { errorHandler } from "../utils/messages";

export function criarMensagem(params) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .post("/mensagem-app?order=dataCriacao&orderType=asc", params)
      .then(() => {
        axiosClient
          .put(`/mensagem-app/ler-cidadao/${params?.demandaId}`)
          .then(() => {
            axiosClient
              .get(
                `/mensagem-app/mensagens/${params?.demandaId}?order=dataCriacao&orderType=asc`
              )
              .then((resp) => {
                store.dispatch({
                  type: "chat/mensagensRecebidas",
                  payload: resp.data,
                });
                store.dispatch({ type: "loading/loadingStop" });
              })
              .catch((e) => {
                store.dispatch({ type: "loading/loadingStop" });
                errorHandler(e);
              }),
              store.dispatch({ type: "loading/loadingStop" });
          })
          .catch((e) => {
            store.dispatch({ type: "loading/loadingStop" });
            errorHandler(e);
          }),
          store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export function getMensagens(demandaID) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .put(`/mensagem-app/ler-cidadao/${demandaID}`)
      .then(() => {
        axiosClient
          .get(
            `/mensagem-app/mensagens/${demandaID}?order=dataCriacao&orderType=asc`
          )
          .then((resp) => {
            store.dispatch({
              type: "chat/mensagensRecebidas",
              payload: resp.data,
            });
            store.dispatch({ type: "loading/loadingStop" });
          })
          .catch((e) => {
            store.dispatch({ type: "loading/loadingStop" });
            errorHandler(e);
          }),
          store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export function limparListaMensagens() {
  return store.dispatch({ type: "chat/limparMensagens" });
}

export function getNotificacoesMensagens(usuarioId) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .get(`/mensagem-app/notificacoes-mensagens?pessoaId=${usuarioId}`)
      .then((resp) => {
        store.dispatch({
          type: "chat/notificacoesRecebidas",
          payload: resp.data,
        });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}
