import React, { useRef } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { Styles } from "../../styles/Styles";
import CustomText from "../../components/text/CustomText";
import { BLACK, KEPPEL, TUNDORA, WHITE } from "../../utils/cores";
import { SITUACOES } from "../../utils/situacoes";
import { ajustarString } from "../../utils";

function HistoricoDenuncia(props) {
  const { historico, protocolo, dataCriacao } = props;
  const scrollViewRef = useRef();

  const getCorTexto = (situacao) => {
    if (situacao === "ARQUIVADO" || situacao === "EM_ANALISE_DE_RISCO")
      return WHITE;
    return BLACK;
  };

  const podeMostrarParecer = (historico) => {
    if (
      historico?.situacaoDemanda === "CANCELADA" ||
      historico?.situacaoDemanda === "ARQUIVADO"
    ) {
      return historico?.descricao?.length > 0;
    }
  };

  const podeMostrarVariavel = (variavel) => {
    return variavel !== undefined && variavel !== "" ? true : false;
  };

  const despachoGuiado = (detalhes) => {
    const textosBreak = [
      "ALTERADA A SITUAÇÃO DA DEMANDA PARA O.S. EXECUTADA, FISCALIZADA CONSTATADA.",
      "ALTERADA A SITUAÇÃO DA DEMANDA PARA O.S. EXECUTADA, FISCALIZADA NÃO CONSTATADA.",
      "ALTERADA A SITUAÇÃO DA DEMANDA PARA O.S. EXECUTADA, NÃO FISCALIZADA.",
      "DEMANDA NÃO EXECUTADA, RETORNOU A SITUAÇÃO O.S. GERADA.",
    ];
    let naoDispacho = true;
    if (podeMostrarVariavel(detalhes)) {
      naoDispacho = textosBreak?.indexOf(detalhes?.toUpperCase()) < 0;
    }
    return naoDispacho;
  };

  const arrayHistorico = historico?.filter((h) => {
    const textoVerificado = h?.detalhe?.toUpperCase();
    const textosBloqueados = [
      "FOI ASSOCIADA A ESTA DEMANDA",
      "ADICIONADO NA ROTA",
      "REMOVIDA DA ROTA",
      "PARA EM ANÁLISE DE GEO",
      "ATUALIZAÇÃO DA DEMANDA",
      "DEMANDA ALTERADA.",
    ];
    const naoPossuiBloqueio =
      textosBloqueados?.filter((t) => textoVerificado?.includes(t))?.length <=
      0;
    return naoPossuiBloqueio;
  });

  return (
    <>
      <Pressable
        style={[
          {
            backgroundColor: TUNDORA,
            padding: 10,
            width: 50,
            height: 50,
            display: "flex",
            alignSelf: "flex-end",
          },
          Styles.botaoIcone,
        ]}
        onPress={props.fecharModal}
      >
        <FontAwesome name="close" size={24} color={WHITE} />
      </Pressable>
      <CustomText tamanhoFonte={24} customColor={WHITE} isBold>
        Histórico
      </CustomText>
      <View style={{ marginBottom: 20, marginTop: 15 }}>
        <CustomText tamanhoFonte={15} customColor={WHITE}>
          {`Protocolo: ${protocolo}`}
        </CustomText>
        <CustomText tamanhoFonte={15} customColor={WHITE}>
          {`Data de criação: ${dataCriacao}`}
        </CustomText>
      </View>
      <ScrollView
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: true })
        }
      >
        {arrayHistorico?.map((historico, key) => {
          if (historico?.situacaoDemanda === undefined) {
            return (
              despachoGuiado(historico?.detalhe) && (
                <View
                  key={key}
                  style={[
                    Styles.containerLinhasHistorico,
                    {
                      backgroundColor: KEPPEL,
                    },
                  ]}
                >
                  {(podeMostrarVariavel(historico?.detalhe) ||
                    podeMostrarVariavel(historico?.descricao)) && (
                    <CustomText
                      style={{ marginBottom: 5, textAlign: "center" }}
                      tamanhoFonte={15}
                      customColor={WHITE}
                      isBold
                    >
                      {`Data/Hora: ${
                        historico?.dataCriacao || dataCriacao || "Não informado"
                      }`}
                    </CustomText>
                  )}
                  {podeMostrarVariavel(historico?.detalhe) && (
                    <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
                      {`⭐ DETALHES`}
                    </CustomText>
                  )}
                  {podeMostrarVariavel(historico?.detalhe) && (
                    <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
                      {ajustarString(
                        historico?.detalhe?.toString()?.toLowerCase()
                      ) || "Não informado"}
                    </CustomText>
                  )}
                  {podeMostrarVariavel(historico?.descricao) && (
                    <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
                      {`\n📃 DESCRIÇÃO`}
                    </CustomText>
                  )}
                  {podeMostrarVariavel(historico?.descricao) && (
                    <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
                      {ajustarString(
                        historico?.descricao?.toString()?.toLowerCase()
                      ) || "Não informado"}
                    </CustomText>
                  )}
                </View>
              )
            );
          }
          return (
            <View
              key={key}
              style={[
                Styles.containerLinhasHistorico,
                {
                  backgroundColor: SITUACOES[historico?.situacaoDemanda]?.color,
                },
              ]}
            >
              <CustomText
                style={{ marginBottom: 5, textAlign: "center" }}
                tamanhoFonte={15}
                customColor={getCorTexto(historico?.situacaoDemanda)}
                isBold
              >
                {`Data/Hora: ${
                  historico?.dataCriacao || dataCriacao || "Não informado"
                }`}
              </CustomText>
              <View style={{ flexDirection: "row", marginBottom: 10 }}>
                <CustomText style={{ paddingRight: 10 }}>
                  {SITUACOES[historico?.situacaoDemanda]?.icon}
                </CustomText>
                <CustomText
                  tamanhoFonte={15}
                  customColor={getCorTexto(historico?.situacaoDemanda)}
                  isBold
                >
                  {`${SITUACOES[historico?.situacaoDemanda]?.nome}`}
                </CustomText>
              </View>
              <CustomText
                tamanhoFonte={15}
                customColor={getCorTexto(historico?.situacaoDemanda)}
              >
                {`${SITUACOES[historico?.situacaoDemanda]?.sobre}`}
              </CustomText>
              {podeMostrarParecer(historico) && (
                <CustomText
                  style={{
                    marginTop: 15,
                    marginBottom: 5,
                    textAlign: "center",
                  }}
                  tamanhoFonte={15}
                  customColor={getCorTexto(historico?.situacaoDemanda)}
                  isBold
                >
                  {`SOBRE A SITUAÇÃO`}
                </CustomText>
              )}
              {podeMostrarParecer(historico) && (
                <CustomText
                  style={{ textAlign: "center" }}
                  tamanhoFonte={15}
                  customColor={getCorTexto(historico?.situacaoDemanda)}
                >
                  {historico?.descricao}
                </CustomText>
              )}
            </View>
          );
        })}
      </ScrollView>
    </>
  );
}

export default HistoricoDenuncia;
