import React from "react";
import { View, ScrollView } from "react-native";

import LogoView from "../../components/visualizacao/LogoView";
import { Styles } from "../../styles/Styles";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";
import { BLACK } from "../../utils/cores";
import CardGenerator from "../../components/card/CardGenerator";
import TextoPoliticaPrivacidade from "../../components/text/TextoPoliticaPrivacidade";
import { eAplicativo } from "../../utils";
import { navigate } from "../../utils/navigationService";
import config from "../../config";
import { useSelector } from "react-redux";

function PoliticaPrivacidade({}) {
  const usuarioLogado = useSelector((state) => state?.auth?.validToken);

  const acaoVoltar = () => {
    if (eAplicativo) {
      navigate(usuarioLogado ? "Base" : "Login");
      return null;
    }

    window.location.href = config.URI;
  };

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="POLÍTICA DE PRIVICIDADE" />
      </View>
      <BotaoVoltar acao={() => acaoVoltar()} />
      <ScrollView style={Styles.larguraWeb}>
        <CardGenerator
          semEspacoEntreCards
          style={{ alignSelf: "center" }}
          tipo={"TEXTO_APENAS"}
          corTexto={BLACK}
          tamanhoTexto={15}
          texto={TextoPoliticaPrivacidade()}
        />
      </ScrollView>
    </View>
  );
}

export default PoliticaPrivacidade;
