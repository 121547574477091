import React, { useEffect, useState } from "react";
import { FAB, Modal, Portal } from "react-native-paper";
import { View, ScrollView } from "react-native";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { limparListaMensagens } from "../../api/chat";

import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import CustomText from "../../components/text/CustomText";
import CardGenerator from "../../components/card/CardGenerator";
import { SITUACOES } from "../../utils/situacoes";
import axiosClient from "../../utils/axiosClient";
import { getDetalheDemanda } from "../../api/denuncia";
import HistoricoDenuncia from "./HistoricoDenuncia";
import ChatDenuncia from "./ChatDenuncia";
import { BLACK, WHITE } from "../../utils/cores";
import { ajustarString } from "../../utils";
import { toastr } from "../../utils/messages";
import { TirarMensagemChat } from "../../api/auth";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";
import BotaoIrParaTelaInicial from "../../components/buttons/BotaoIrParaTelaInicial";

function DetalheMinhasDenuncias({ route, navigation }) {
  const [modalHistorico, setModalHistorico] = useState(false);
  const [modalChat, setModalChat] = useState(false);
  const emailUsuario = useSelector((state) => state?.auth?.user?.email);
  const data = useSelector((state) => state.denuncias?.detail);
  const mensagens = useSelector((state) => state?.chat?.mensagens) || [];
  const mostrarMensagemChat = useSelector(
    (state) => state?.auth?.mostrarMensagemChat
  );
  const autoAbrirChat = () => {
    if (route.params?.vemDasNotificacoes === true) setModalChat(true);
    return null;
  };

  useEffect(() => {
    autoAbrirChat();
    limparListaMensagens();
  }, []);

  const {
    protocoloDenuncia,
    denunciaId,
    demandaPrincipalId,
    vemDoCadastro,
  } = route.params;

  const { error } = useQuery(
    "fetchDetalheDenuncia",
    () => getDetalheDemanda(protocoloDenuncia),
    {
      enabled: !!protocoloDenuncia,
    }
  );

  const { data: historico } = useQuery("historico", () =>
    axiosClient.get(
      `/cidadaos/demanda/${
        demandaPrincipalId ? demandaPrincipalId : denunciaId
      }/historico`
    )
  );

  // const selecionarDocumento = async () => {
  //   try {
  //     const result = await DocumentPicker.getDocumentAsync({ type: "/" });
  //     if (!result.cancelled && result.type !== "cancel") console.log(result);
  //     setArquivo(result, Number(denunciaId), protocoloDenuncia);
  //   } catch (error) {
  //     toastr.warning("Falha ao adicionar arquivo, tente novamente mais tarde!");
  //     return null;
  //   }
  // };

  const dataCriacao = data && data?.dataCriacao;
  const situacaoDemanda = data?.demandaPrincipal?.situacao
    ? data?.demandaPrincipal?.situacao
    : data?.situacao;

  const _MS_PER_DAY = 1000 * 3600 * 24;
  const dataAtual = new Date().getTime();
  const diaCriacao = dataCriacao?.split("/")[0];
  const mesCriacao = dataCriacao?.split("/")[1];
  const anoCriacao = dataCriacao?.split("/")[2];
  const dataCriado = mesCriacao + "/" + diaCriacao + "/" + anoCriacao;
  const criadoEm = new Date(dataCriado).getTime();
  const diferencaDias = Math.abs(dataAtual - criadoEm);
  const diasDesdeCriado = Math.ceil(diferencaDias / _MS_PER_DAY);

  const pessoaGeradora = data?.pessoaGeradora?.email === emailUsuario;

  const condicoesPadroes =
    pessoaGeradora &&
    (diasDesdeCriado > 30 ||
      situacaoDemanda === "INFORMACAO_INSUFICIENTE" ||
      situacaoDemanda === "FISCALIZADO_NAO_CONSTATADA" ||
      situacaoDemanda === "NAO_FISCALIZADA" ||
      situacaoDemanda === "ARQUIVADO");

  const podeMostrarChat =
    situacaoDemanda === "CONCLUIDA"
      ? mensagens?.length > 0 && condicoesPadroes
      : condicoesPadroes;

  return (
    <View style={Styles.containerPrincipal}>
      {historico?.data?.list && (
        <Portal>
          <Modal
            contentContainerStyle={Styles.containerModal}
            animationType="slide"
            dismissable
            visible={modalHistorico}
            onDismiss={() => setModalHistorico(false)}
          >
            <HistoricoDenuncia
              protocolo={data?.protocolo}
              dataCriacao={dataCriacao}
              historico={historico?.data?.list}
              fecharModal={() => setModalHistorico(false)}
            />
          </Modal>
        </Portal>
      )}
      {podeMostrarChat && modalChat && (
        <Portal>
          <Modal
            contentContainerStyle={Styles.containerModal}
            animationType="slide"
            dismissable
            visible={modalChat}
            onDismiss={() => setModalChat(false)}
          >
            <ChatDenuncia
              protocolo={protocoloDenuncia}
              dataCriacao={dataCriacao}
              denunciaId={denunciaId}
              situacaoDemanda={situacaoDemanda}
              comunicacaoEncerrada={data?.comunicacaoEncerrada}
              fecharModal={() => setModalChat(false)}
            />
          </Modal>
        </Portal>
      )}
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="DETALHES DA DEMANDA" />
      </View>
      <BotaoVoltar
        acao={() =>
          vemDoCadastro === true
            ? navigation.navigate("Base")
            : navigation.goBack()
        }
      />
      <BotaoIrParaTelaInicial />
      {podeMostrarChat && (
        <FAB
          style={{
            backgroundColor: WHITE,
            position: "absolute",
            right: 10,
            top: 50,
          }}
          small
          icon="chat"
          onPress={() => {
            if (mostrarMensagemChat) {
              toastr.warning(
                "A comunicação não é instantânea, então, ao enviar sua mensagem basta aguardar que será atendida assim que possível"
              );
              TirarMensagemChat();
            }
            setModalChat(true);
          }}
        />
      )}
      <ScrollView scrollIndicatorInsets={{ right: 1 }}>
        {error &&
          !data &&
          toastr.error(
            "Denúncia não carregada corretamente, tente novamente mais tarde."
          )}
        {data && (
          <View style={Styles.larguraWeb}>
            <View style={Styles.containerLogo}>
              <CardGenerator
                semEspacoEntreCards
                style={{ alignSelf: "center" }}
                tipo={"TEXTO_APENAS"}
                corTexto={BLACK}
                tamanhoTexto={15}
                texto={`DATA CRIAÇÃO: ${dataCriacao}`}
              />
              <CardGenerator
                semEspacoEntreCards
                alinhamentoTexto="left"
                tipo={"TEXTO_APENAS"}
                tamanhoTexto={15}
                texto={`NÚMERO DO PROTOCOLO:\n${
                  protocoloDenuncia || "Não Informado"
                }\n\nENDEREÇO:\n${data?.logradouro || "N/I"}, ${
                  data?.numero || "N/I"
                }, ${data?.complemento || "N/I"}, ${
                  data?.bairro || "N/I"
                }\n\nFISCALIZADO:\n${
                  data?.fiscalizado?.razaoSocial || "Não Informado"
                }\n\nGRUPO DEMANDA:\n${
                  data?.grupoDemanda?.nome || "Não Informado"
                }\n\nTURNOS PARA FISCALIZAÇÃO:\n${
                  ajustarString(
                    data?.turnos
                      ?.toString()
                      ?.toLowerCase()
                      ?.replace("manha", "manhã")
                  ) || "Não Informado"
                }\n\nDIAS DE FUNCIONAMENTO:\n${
                  ajustarString(
                    data?.diasFuncionamento
                      ?.toString()
                      ?.toLowerCase()
                      ?.replace("terca", "terça")
                      ?.replace("sabado", "sábado")
                  ) || "Não Informado"
                }`}
              />
              {data?.descricao && (
                <CardGenerator
                  semEspacoEntreCards
                  alinhamentoTexto="left"
                  tipo={"TEXTO_APENAS"}
                  tamanhoTexto={15}
                  texto={`DESCRIÇÃO:\n${ajustarString(
                    data?.descricao?.toLowerCase()
                  )}`}
                />
              )}
              {/* {data?.pessoaGeradora?.email === emailUsuario && (
                <CardGenerator
                  semEspacoEntreCards
                  tipo={"DISPLAY_ARQUIVOS"}
                  corTexto={BLACK}
                  tamanhoTexto={15}
                  arquivos={data?.arquivos?.filter((arq) =>
                    arq?.nome?.includes("-t_denunciante_t-")
                  )}
                  tituloBotao="ADICIONAR ARQUIVO"
                  denunciaId={data?.id}
                  acao={() => selecionarDocumento()}
                  protocoloDenuncia={protocoloDenuncia}
                />
              )} */}
              {SITUACOES[situacaoDemanda]?.nome && (
                <CardGenerator
                  semEspacoEntreCards
                  tipo={"TEXTO_COM_BOTAO"}
                  corCard={WHITE}
                  tamanhoTexto={15}
                  acao={() => setModalHistorico(true)}
                  tituloBotao={"HISTÓRICO DENÚNCIA"}
                  alinhamentoTexto="center"
                  texto={
                    <>
                      <CustomText tamanhoFonte={18} customColor={BLACK} isBold>
                        {`${SITUACOES[situacaoDemanda]?.nome}\n`}
                      </CustomText>
                      <CustomText tamanhoFonte={18} customColor={BLACK}>
                        {SITUACOES[situacaoDemanda]?.sobre}
                      </CustomText>
                    </>
                  }
                />
              )}
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
}

export default DetalheMinhasDenuncias;
