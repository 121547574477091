import React, { useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import CustomText from "../text/CustomText";
import { Styles } from "../../styles/Styles";
import CustomButton from "../buttons/CustomButton";
import { AMARANTH, BLACK, DARK_BLUE, TUNDORA, WHITE } from "../../utils/cores";
import { removerArquivo } from "../../api/denuncia";
import { Button, Dialog, Paragraph, Portal } from "react-native-paper";
import { dadoExiste, getNomeArquivo } from "../../utils";
import consts from "../../config";

function CardGenerator(props) {
  const {
    exibirBotaoExcluir,
    exibirVisualizarArquivo,
    exibirBotaoAdicionar,
  } = props;
  const [confirmarExcluir, setConfirmarExcluir] = useState(false);
  const [arquivoAtual, setArquivoAtual] = useState(null);
  const tipo = {
    TEXTO_APENAS: "TEXTO_APENAS",
    DISPLAY_ARQUIVOS: "DISPLAY_ARQUIVOS",
    TEXTO_COM_BOTAO: "TEXTO_COM_BOTAO",
  };

  const getCorCard = () => {
    return props.tipo === tipo.TEXTO_APENAS
      ? WHITE
      : props.tipo === tipo.TEXTO_COM_BOTAO
      ? WHITE
      : props.tipo === tipo.DISPLAY_ARQUIVOS
      ? WHITE
      : WHITE;
  };

  return (
    <>
      <Portal>
        <Dialog
          visible={confirmarExcluir}
          onDismiss={() => {
            setConfirmarExcluir(false);
            setArquivoAtual(null);
          }}
        >
          <Dialog.Content>
            <Paragraph>Confirma a exclusão do arquivo?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                setConfirmarExcluir(false);
                setArquivoAtual(null);
              }}
            >
              Cancelar
            </Button>
            <Button
              onPress={async () => {
                setConfirmarExcluir(false);
                await removerArquivo(
                  arquivoAtual?.id,
                  arquivoAtual?.nome,
                  props?.protocoloDenuncia || null
                );
                setArquivoAtual(null);
              }}
            >
              Sim
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View
        style={[
          Styles.containerCard,
          {
            marginTop: props?.semEspacoEntreCards ? 0 : 30,
            backgroundColor: props.corCard || getCorCard(),
          },
        ]}
      >
        {props.tipo === tipo.TEXTO_APENAS && (
          <CustomText
            style={{ textAlign: props.alinhamentoTexto || "auto" }}
            tamanhoFonte={props.tamanhoTexto || 16}
            customColor={props.corTexto || BLACK}
            isBold
          >
            {props.texto}
          </CustomText>
        )}
        {props.tipo === tipo.TEXTO_COM_BOTAO && (
          <>
            <CustomText
              style={{ textAlign: props.alinhamentoTexto || "auto" }}
              tamanhoFonte={props.tamanhoTexto || 16}
              customColor={props.corTexto || BLACK}
              isBold
            >
              {props.texto}
            </CustomText>
            <View style={Styles.containerBotoesDuplos}>
              <CustomButton
                tipo="AJUSTAVEL"
                tituloBotao={props.tituloBotao}
                corBotao={props.corBotao || TUNDORA}
                corTextoBotao={props.corTextoBotao || WHITE}
                acaoBotao={props.acao}
              />
            </View>
          </>
        )}
        {props.tipo === tipo.DISPLAY_ARQUIVOS && (
          <>
            <ScrollView
              scrollIndicatorInsets={{ right: 1 }}
              style={{ maxHeight: 200 }}
            >
              {props.arquivos?.length > 0 &&
                props.arquivos?.map((arquivo, key) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 10,
                        marginBottom: 5,
                        borderRadius: 15,
                        backgroundColor: key % 2 == 0 ? TUNDORA : DARK_BLUE,
                        flex: 1,
                      }}
                      key={key}
                    >
                      <View style={{ flex: 10, marginRight: 10 }}>
                        <CustomText
                          tamanhoFonte={props.tamanhoTexto}
                          customColor={WHITE}
                          isBold
                        >
                          {`Inserido em:\n${arquivo?.nome
                            ?.split("_")[0]
                            ?.replace(/-/g, "/")}`}
                        </CustomText>
                        <CustomText
                          tamanhoFonte={props.tamanhoTexto}
                          customColor={WHITE}
                          isBold
                        >
                          {`\nNome do arquivo:\n${getNomeArquivo(arquivo)}`}
                        </CustomText>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 2,
                          position: "absolute",
                          top: 10,
                          right: 10,
                        }}
                      >
                        {[
                          exibirBotaoExcluir !== false && {
                            name: "trash",
                            color: AMARANTH,
                            action: () => {
                              setArquivoAtual(arquivo);
                              setConfirmarExcluir(true);
                            },
                          },
                          exibirVisualizarArquivo === true && {
                            name: "eye",
                            color: TUNDORA,
                            action: () => {
                              var str = Object.keys(arquivo)
                                ?.map((key) => key + "=" + arquivo[key])
                                ?.join("&");
                              let url = `${consts.API_URL}/arquivos/visualizar?${str}`;

                              window.open(url, "_blank");
                            },
                          },
                        ]?.map((b, index) => {
                          if (!dadoExiste(b?.name)) {
                            return null;
                          }
                          return (
                            <Pressable
                              style={{
                                backgroundColor: WHITE,
                                margin: 5,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              key={`botao-${index}`}
                              onPress={b.action}
                            >
                              <FontAwesome
                                style={{ margin: 5 }}
                                name={b.name}
                                size={35}
                                color={b.color}
                              />
                            </Pressable>
                          );
                        })}
                      </View>
                    </View>
                  );
                })}
            </ScrollView>
            {props.arquivos?.length > 0 && (
              <CustomText
                style={{ textAlign: "center", marginTop: 10 }}
                tamanhoFonte={props.tamanhoTexto || 16}
                customColor={props.corTexto || BLACK}
                isBold
              >
                {(props.arquivos?.length > 99
                  ? "99+"
                  : props.arquivos?.length || "0") +
                  (props.arquivos?.length === 1
                    ? " arquivo cadastrado"
                    : " arquivos cadastrados")}
              </CustomText>
            )}
            <View style={Styles.containerBotoesDuplos}>
              {exibirBotaoAdicionar !== false && (
                <CustomButton tipo="ADICIONAR_ARQUIVO" acaoBotao={props.acao} />
              )}
            </View>
          </>
        )}
      </View>
    </>
  );
}

export default CardGenerator;
