import store from "../redux/store";

export default function authHeader() {
  const userToken = store.getState().auth?.token;

  if (userToken) {
    return { Authorization: userToken };
  } else {
    return {};
  }
}
