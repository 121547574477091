import React, { useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { useForm } from "react-hook-form";
import { ProgressBar, Colors } from "react-native-paper";
import { useDispatch } from "react-redux";
import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import CustomButton from "../../../components/buttons/CustomButton";
import { Input } from "../../../components/form/Input";
import { BLACK, TUNDORA, WHITE } from "../../../utils/cores";
import { toastr } from "../../../utils/messages";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function Step2Fiscalizado({ navigation }) {
  const [conheceFiscalizado, setConheceFiscalizado] = useState(null);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let dados = { ...data, conheceFiscalizado };
    const pressionado = data?.id !== undefined && data?.id !== null;
    if (pressionado) {
      if (data?.id === "SIM") {
        setConheceFiscalizado("SIM");
        return null;
      }
      setConheceFiscalizado("NAO");
      delete dados.id;
    } else {
      const camposVazios =
        conheceFiscalizado === "SIM" && errors?.nomeFiscalizado !== undefined;
      if (camposVazios) {
        toastr.warning("Você precisa preencher todos os campos obrigatórios!");
        return null;
      }
      if (conheceFiscalizado === null) {
        toastr.warning(
          "Selecione uma opção se você conhece ou não o fiscalizado!"
        );
        return null;
      }
    }
    dispatch({ type: "cadastroDenuncia/infoFiscalizadoSent", payload: dados });
    navigation.navigate("Localizacao");
  };

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      style={Styles.containerPrincipal}
    >
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CADASTRO DO FISCALIZADO" />
      </View>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.1}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <ScrollView>
          <View style={{ margin: 5 }}>
            <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
              Conhece o fiscalizado? *
            </CustomText>
          </View>
          {[
            { id: "SIM", nome: "Sim" },
            { id: "NAO", nome: "Não" },
          ]?.map((c, index) => {
            return (
              <Pressable
                key={"conhece-fiscalizado-" + index}
                onPress={() => handleSubmit(onSubmit({ id: c.id }))}
                style={
                  (Styles.containerCard,
                  {
                    backgroundColor:
                      conheceFiscalizado === c.id ? TUNDORA : WHITE,
                    margin: 5,
                    padding: 10,
                    borderRadius: 15,
                    textAlign: "center",
                  })
                }
              >
                <CustomText
                  tamanhoFonte={15}
                  customColor={conheceFiscalizado === c.id ? WHITE : BLACK}
                >
                  {c.nome}
                </CustomText>
              </Pressable>
            );
          })}
          {conheceFiscalizado === "SIM" && (
            <>
              <Input
                control={control}
                name="nomeFiscalizado"
                label="Qual nome ou razão social? *"
                rules={{ required: true }}
                error={errors?.nomeFiscalizado === undefined ? "NAO" : "SIM"}
                returnKeyType="next"
              />
              <Input
                control={control}
                name="nomeResponsavel"
                label="Qual o nome da pessoa responsável?"
                rules={{ required: false }}
                error={errors?.nomeResponsavel === undefined ? "NAO" : "SIM"}
                returnKeyType="next"
              />
              <Input
                control={control}
                name="telefoneResponsavel"
                label="Qual o telefone do responsável?"
                keyboardType="number-pad"
                rules={{ required: false }}
                error={
                  errors?.telefoneResponsavel === undefined ? "NAO" : "SIM"
                }
                returnKeyType="next"
              />
              <Input
                control={control}
                name="emailResponsavel"
                label="Qual o e-mail do responsável?"
                rules={{ required: false }}
                autoCapitalize="none"
                error={errors?.emailResponsavel === undefined ? "NAO" : "SIM"}
                returnKeyType="done"
                onSubmitEditing={handleSubmit(onSubmit)}
              />
            </>
          )}
          <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
            <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
            <CustomButton
              tipo="PROXIMO"
              acaoBotao={
                conheceFiscalizado === "SIM"
                  ? handleSubmit(onSubmit)
                  : () => onSubmit({ conheceFiscalizado })
              }
            />
          </View>
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>
  );
}

export default Step2Fiscalizado;
