import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    permissions: [],
    validToken: false,
    appReady: false,
    testUser: null,
    mostrarMensagemChat: true,
    codigoResetValido: false,
  },
  reducers: {
    userOnStorage: (state, action) => {
      let user = { ...action.payload };
      delete user.senha;
      if (action.payload) {
        return {
          ...state,
          user,
          token: user.token,
          validToken: true,
          appReady: true,
        };
      }
      return { ...state, validToken: false, user: null, appReady: true };
    },

    tokenValidated: (state, action) => {
      if (action.payload) {
        return { ...state, validToken: true, appReady: true };
      } else {
        return {
          ...state,
          validToken: false,
          user: null,
          permissions: null,
          token: null,
          appReady: true,
        };
      }
    },
    userLogged: (state, action) => {
      return {
        ...state,
        user: action.payload,
        permissions: action.payload.permissoes,
        token: action.payload.token,
        testUser: action.payload?.nome || null,
        validToken: true,
        appReady: true,
      };
    },
    permissionsUpdated: (state, action) => {
      return {
        ...state,
        permissions: action.payload,
      };
    },
    userLogout: (state) => {
      return {
        ...state,
        validToken: false,
        user: null,
        permissions: null,
        token: null,
        testUser: null,
        appReady: true,
      };
    },
    appReady: (state) => {
      return {
        ...state,
        appReady: true,
      };
    },
    tirarMensagemChat: (state) => {
      return {
        ...state,
        mostrarMensagemChat: false,
      };
    },
    validarCodigo: (state, action) => {
      return {
        ...state,
        codigoResetValido: action.payload,
      };
    },
  },
});

export const {
  userOnStorage,
  tokenValidated,
  userLogged,
  userLogout,
  permissionsUpdated,
  appReady,
  tirarMensagemChat,
} = authSlice.actions;

export default authSlice.reducer;
