export const SITUACOES = {
  CADASTRADO: {
    nome: "Recebida",
    color: "#dadada",
    sobre: "Esta denúncia foi recebida no sistema e está aguardando análise",
    progress: "0.1",
    icon: "➕",
  },
  AGUARDANDO_ANALISE: {
    nome: "Em Análise",
    color: "#a5a5a5",
    sobre: "Sua denúncia foi recebida no sistema e está em processo de análise",
    progress: "0.1",
    icon: "🔍",
  },
  EM_ANALISE_DE_RISCO: {
    nome: "Em Análise",
    color: "#ca4c4c",
    sobre: "Sua denúncia foi recebida no sistema e está em processo de análise",
    progress: "0.1",
    icon: "🔍",
  },
  EM_ANALISE_GEO: {
    nome: "Em Análise",
    color: "#2fbd55",
    sobre: "Sua denúncia foi recebida no sistema e está em processo de análise",
    progress: "0.1",
    icon: "🔍",
  },
  ASSOCIADA: {
    nome: "Associada a Uma Outra Demanda",
    color: "#a9cca6",
    sobre: "Esta denúncia foi recebida no sistema e associada a uma semelhante",
    progress: "0.5",
    icon: "📑",
  },
  INFORMACAO_INSUFICIENTE: {
    nome: "Informações Insuficientes",
    color: "#ccb9a6",
    sobre:
      "Após análise, constatamos que faltam informações importantes nesta denúncia e, por isso, não poderemos realizar a fiscalização",
    progress: "0.7",
    icon: "🔴",
  },
  COM_PENDENCIA: {
    nome: "Com pendência",
    color: "#f5f757",
    sobre:
      "Após análise, constatamos que existem pendências nesta denúncia e, por isso, não poderemos fazer a fiscalização",
    progress: "1",
    icon: "🕘",
  },
  BLOQUEADA: {
    nome: "Bloqueada",
    color: "#b5b5b5",
    sobre:
      "Após análise, constatamos que estamos impedidos de fazer esta fiscalização em cumprimento a ordem judicial",
    progress: "1",
    icon: "🔒",
  },
  OS_GERADA: {
    nome: "Ordem de Serviço Gerada",
    color: "#ebf6be",
    sobre: "Esta denúncia foi recebida, analisada e validada para fiscalização",
    progress: "0.2",
    icon: "📋",
  },
  CANCELADA: {
    nome: "Cancelada",
    color: "#b9b9b9",
    sobre: "Esta denúncia foi analisada e cancelada, conforme justificativa",
    progress: "1",
    icon: "❌",
  },
  OS_GERADA_OPERACAO: {
    nome: "Ordem de Serviço Gerada",
    color: "#bca6cc",
    sobre: "Esta denúncia foi analisada e já está na fila para fiscalização",
    progress: "0.5",
    icon: "📋",
  },
  EM_FISCALIZACAO: {
    nome: "Em Rota de Fiscalização",
    color: "#d4ebff",
    sobre: "Esta denúncia foi analisada e já está na fila para fiscalização",
    progress: "0.3",
    icon: "📝",
  },
  OS_DIRETA: {
    nome: "Em Rota de Fiscalização",
    color: "#e3969f",
    sobre: "Esta denúncia foi analisada e já está na fila para fiscalização",
    progress: "0.2",
    icon: "📃",
  },
  FISCALIZADO_NAO_CONSTATADA: {
    nome: "Fiscalizada",
    color: "#fdd1c5",
    sobre:
      "Foi realizada fiscalização no local, mas não foi verificada nenhuma infração",
    progress: "1",
    icon: "✔️",
  },
  FISCALIZADO_CONSTATADA: {
    nome: "Fiscalizada",
    color: "#d9fdc5",
    sobre: "Foi realizada fiscalização no local e foi verificada infração",
    progress: "1",
    icon: "✔️",
  },
  NAO_FISCALIZADA: {
    nome: "Não fiscalizada",
    color: "#efc5fd",
    sobre:
      "A equipe de fiscais foi ao local, mas não conseguiu realizar a fiscalização",
    progress: "0.9",
    icon: "💬",
  },
  NAO_EXECUTADA: {
    nome: "Ordem de Serviço Gerada",
    color: "#4cbdca",
    sobre: "Sua denúncia não pôde ser fiscalizada e será redistribuída",
    progress: "0.4",
    icon: "📃",
  },
  ARQUIVADO: {
    nome: "Arquivada",
    color: "#5d518a",
    sobre:
      "O processo correspondente a esta denúncia chegou ao final e foi arquivado",
    progress: "1",
    icon: "🗃️",
  },
  CONCLUIDA: {
    nome: "Concluída",
    color: "#a6b7cc",
    sobre:
      "A fiscalização terminou o trabalho e encaminhou o processo para devidas providências",
    progress: "1",
    icon: "✅",
  },
};
