import React from "react";
import { View } from "react-native";
import { Styles } from "../../styles/Styles";
import { AMARANTH, WHITE } from "../../utils/cores";
import { Dialog, Portal } from "react-native-paper";
import CustomText from "../text/CustomText";
import { Feather } from "@expo/vector-icons";

function AvisoView() {
  return (
    <View style={Styles.containerLogo}>
      <Portal>
        <Dialog
          visible={true}
          style={{
            display: "flex",
            backgroundColor: AMARANTH,
            minHeight: "30%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 15,
          }}
        >
          <Dialog.Content>
            <CustomText
              style={{ textAlign: "center", margin: 15 }}
              tamanhoFonte={16}
              customColor={WHITE}
              isBold
            >
              Sistema em manutenção, por favor, tente novamente mais tarde.
            </CustomText>
            <Feather
              style={{ alignSelf: "center" }}
              name="clock"
              size={50}
              color={WHITE}
            />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </View>
  );
}

export default AvisoView;
