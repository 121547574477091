import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Pressable, ScrollView, View } from "react-native";
import { TextInput } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  criarMensagem,
  getMensagens,
  getNotificacoesMensagens,
  limparListaMensagens,
} from "../../api/chat";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Styles } from "../../styles/Styles";
import CustomText from "../../components/text/CustomText";
import {
  BLACK,
  DARK_BLUE,
  KEPPEL,
  TANGO,
  TUNDORA,
  WHITE,
} from "../../utils/cores";
import { toastr } from "../../utils/messages";

function ChatDenuncia(props) {
  const {
    protocolo,
    dataCriacao,
    denunciaId,
    comunicacaoEncerrada,
    situacaoDemanda,
  } = props;
  const mensagemRef = useRef(null);
  const [mensagem, setMensagem] = useState("");
  const scrollViewRef = useRef();
  const usuarioId = useSelector((state) => state?.auth?.user?.id);

  useEffect(() => {
    limparListaMensagens();
  }, []);

  const {} = useQuery("fetchMinhasDenuncias", () => getMensagens(denunciaId), {
    refetchOnWindowFocus: true,
    enabled: !!denunciaId,
    force: true,
  });

  const {} = useQuery(
    "fetchNotificacoesMensagens",
    () => getNotificacoesMensagens(usuarioId),
    {
      refetchOnWindowFocus: true,
      enabled: !!usuarioId,
      force: true,
    }
  );

  const {
    handleSubmit,
    formState: {},
  } = useForm();
  const mensagens = useSelector((state) => state?.chat?.mensagens) || [];
  const onSubmit = (data) => {
    const payload = {
      demandaId: denunciaId,
      mensagem,
    };
    if (mensagem !== "") {
      criarMensagem(payload);
    } else {
      toastr.warning("Você precisa escrever uma mensagem");
    }
    setMensagem("");
    mensagemRef?.current?.blur();
  };

  const situacoesBreak = ["CONCLUIDA"];
  const podeMostrarTextField =
    comunicacaoEncerrada !== true
      ? situacoesBreak?.indexOf(situacaoDemanda) >= 0
        ? false
        : true
      : false;

  return (
    <>
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row-reverse",
          display: "flex",
          alignSelf: "flex-end",
          width: 120,
        }}
      >
        <Pressable
          style={[
            {
              backgroundColor: TUNDORA,
              padding: 10,
              width: 50,
              height: 50,
              display: "flex",
              alignSelf: "flex-end",
            },
            Styles.botaoIcone,
          ]}
          onPress={props.fecharModal}
        >
          <FontAwesome name="close" size={24} color={WHITE} />
        </Pressable>
        {!comunicacaoEncerrada && (
          <Pressable
            style={[
              {
                backgroundColor: DARK_BLUE,
                padding: 10,
                width: 50,
                height: 50,
                display: "flex",
                alignSelf: "flex-end",
              },
              Styles.botaoIcone,
            ]}
            onPress={() => getMensagens(denunciaId)}
          >
            <FontAwesome name="refresh" size={24} color={WHITE} />
          </Pressable>
        )}
      </View>
      <CustomText tamanhoFonte={24} customColor={WHITE} isBold>
        Comunicação
      </CustomText>
      <View style={{ marginTop: 15 }}>
        <CustomText tamanhoFonte={15} customColor={WHITE}>
          {`Protocolo: ${protocolo}`}
        </CustomText>
        <CustomText tamanhoFonte={15} customColor={WHITE}>
          {`Data de criação: ${dataCriacao}`}
        </CustomText>
      </View>
      {podeMostrarTextField && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 15,
            marginRight: 20,
            paddingTop: 15,
          }}
        >
          <TextInput
            ref={mensagemRef}
            onChangeText={(value) => setMensagem(value)}
            value={mensagem}
            style={[
              Styles.containerInput,
              { flex: 11, width: "95%", marginRight: 15 },
            ]}
            multiline
            returnKeyType="next"
            theme={{
              colors: {
                placeholder: WHITE,
                text: WHITE,
                primary: WHITE,
                underlineColor: "transparent",
              },
            }}
            label={"Digite a sua mensagem"}
          />
          <Pressable
            style={[
              { backgroundColor: TUNDORA, width: 50, height: 50 },
              Styles.botaoIcone,
            ]}
            onPress={handleSubmit(onSubmit)}
          >
            <FontAwesome name="arrow-right" size={24} color={WHITE} />
          </Pressable>
        </View>
      )}
      <ScrollView
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: true })
        }
      >
        {mensagens?.map((chat, key) => {
          return (
            <View
              key={key}
              style={{
                maxWidth: "80%",
                alignSelf:
                  chat.origem === "CIDADAO" ? "flex-end" : "flex-start",
              }}
            >
              <CustomText
                style={{
                  textAlign: chat.origem === "CIDADAO" ? "right" : "left",
                  margin: 5,
                }}
                tamanhoFonte={13}
                customColor={BLACK}
                isBold
              >
                {chat.origem === "CIDADAO" ? "CIDADÃO" : "AGEFIS"}
              </CustomText>
              <View
                style={[
                  Styles.containerCard,
                  {
                    padding: 10,
                    minWidth: 180,
                    backgroundColor: chat.origem === "CIDADAO" ? KEPPEL : TANGO,
                  },
                ]}
              >
                <CustomText
                  tamanhoFonte={15}
                  customColor={chat.origem === "CIDADAO" ? BLACK : WHITE}
                >
                  {chat.mensagem}
                </CustomText>
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-end" }}
                >
                  <CustomText
                    tamanhoFonte={12}
                    customColor={chat.origem === "CIDADAO" ? BLACK : WHITE}
                    style={{ marginTop: 5, marginRight: 5 }}
                  >
                    {chat.dataCriacao}
                  </CustomText>
                  <CustomText>
                    <MaterialCommunityIcons
                      name="check-all"
                      size={24}
                      color={chat.lido !== true ? WHITE : DARK_BLUE}
                    />
                  </CustomText>
                </View>
              </View>
            </View>
          );
        })}
      </ScrollView>
    </>
  );
}

export default ChatDenuncia;
