import React, { useState } from "react";
import { View, ScrollView } from "react-native";
import { Searchbar } from "react-native-paper";
import { useSelector } from "react-redux";

import LogoView from "../../components/visualizacao/LogoView";
import CardGenerator from "../../components/card/CardGenerator";
import { Styles } from "../../styles/Styles";
import { SITUACOES } from "../../utils/situacoes";
import { ajustarString } from "../../utils";
import { getDetalheDemanda } from "../../api/denuncia";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";

function ConsultarDenuncia({ navigation }) {
  const [protocolo, setProtocolo] = useState("");
  const onChangeSearch = (protocolo) => setProtocolo(protocolo);
  const data = useSelector((state) => state.denuncias?.detail);

  const handleClick = () => {
    if (protocolo !== "") {
      getDetalheDemanda(protocolo);
    }
    setProtocolo("");
  };

  const list = data;
  const situacao = list?.demandaPrincipal?.situacao
    ? list?.demandaPrincipal?.situacao
    : list?.situacao;
  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CONSULTAR PROTOCOLO" />
      </View>
      <BotaoVoltar />
      <ScrollView style={Styles.larguraWeb}>
        <Searchbar
          placeholder="Número do Protocolo"
          onChangeText={onChangeSearch}
          value={protocolo}
          onIconPress={handleClick}
          returnKeyType="search"
          onSubmitEditing={handleClick}
        />
        {list?.id && (
          <View style={Styles.containerLogo}>
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              tipo={"TEXTO_COM_BOTAO"}
              tamanhoFonte={15}
              acao={() =>
                navigation.navigate("DetalheMinhasDenuncias", {
                  protocoloDenuncia: list.protocolo,
                  denunciaId: list.id,
                  demandaPrincipalId: list?.demandaPrincipal?.id,
                })
              }
              tituloBotao={"VER DETALHES"}
              texto={
                <>
                  {list?.protocolo && `PROTOCOLO:\n${list.protocolo}`}
                  {list?.dataCriacao &&
                    `\n\nDATA/HORA DA CRIAÇÃO:\n${list.dataCriacao}`}
                  {list?.dataCriacao &&
                    `\n\nDATA/HORA DA DENÚNCIA:\n${list.dataHoraOcorrencia}`}
                  {list?.situacao &&
                    `\n\nSITUAÇÃO:\n${ajustarString(
                      SITUACOES[situacao]?.nome?.toLowerCase()
                    )}`}
                  {list?.descricao &&
                    `\n\nDESCRIÇÃO:\n${ajustarString(
                      list.descricao
                        ?.toLowerCase()
                        ?.substring(0, 150)
                        ?.concat(list.descricao?.length > 150 ? " [...]" : "")
                    )}`}
                </>
              }
            />
          </View>
        )}
      </ScrollView>
    </View>
  );
}

export default ConsultarDenuncia;
