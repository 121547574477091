import { Dimensions, StyleSheet } from "react-native";
import { eAplicativo } from "../utils";
import { WHITE, BLACK, TUNDORA, BLUE_CHILL } from "../utils/cores";

const win = Dimensions.get("window");

export const Styles = StyleSheet.create({
  containerPrincipal: {
    flex: 1,
    padding: 10,
    backgroundColor: BLUE_CHILL,
  },
  containerModal: {
    flex: 1,
    backgroundColor: BLUE_CHILL,
    justifyContent: "center",
    margin: 15,
    padding: 20,
    borderRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  containerLinhasHistorico: {
    marginBottom: 18,
    padding: 10,
    margin: 10,
    borderRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 0.7,
    elevation: 10,
  },
  containerCard: {
    marginBottom: 10,
    width: "95%",
    padding: 20,
    borderRadius: 15,
    alignSelf: "center",
  },
  containerRodape: {
    marginTop: 30,
    marginBottom: 10,
    alignSelf: "center",
    alignItems: "center",
  },
  containerInput: {
    fontSize: 15,
    fontWeight: "bold",
    backgroundColor: BLUE_CHILL,
  },
  containerInputMinhaInfracao: {
    backgroundColor: WHITE,
    borderBottomColor: BLACK,
    borderBottomWidth: 1,
    height: 50,
    fontSize: 16,
  },
  containerBotoesDuplos: {
    margin: 10,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  containerLogo: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 10,
  },
  containerBotaoComIcone: {
    backgroundColor: WHITE,
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 150,
    margin: 10,
    borderRadius: 6,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  containerBottomNav: {
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    position: "absolute",
    padding: 10,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: TUNDORA,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  padraoBotao: {
    margin: 10,
    minWidth: "45%",
    borderRadius: 6,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  botaoIcone: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    shadowColor: BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  imagem: {
    height: win?.height / 3.5,
    width: win?.width / 2,
    marginBottom: 10,
    objectFit: "contain",
  },
  larguraWeb: { width: eAplicativo ? "100%" : "80%", alignSelf: "center" },
  dadosHorizontais: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  barraNomeDenunciante: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: WHITE,
    marginTop: 30,
    borderRadius: 25,
    padding: 10,
  },
  botaoDrawer: {
    padding: 10,
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: TUNDORA,
    borderRadius: 20,
  },
});
