import React from "react";
import { navigate } from "../../utils/navigationService";
import { FAB } from "react-native-paper";
import { TUNDORA } from "../../utils/cores";

const BotaoIrParaTelaInicial = (props) => {
  const { acao, aDireita, semEspacoTopo, estiloBotao = {} } = props;
  const acaoVoltar = () => (props?.acao ? acao() : navigate("Base"));

  const estiloParaLados = aDireita === true ? { right: 10 } : { left: 10 };
  const estiloEspacaoParaCima = semEspacoTopo === true ? {} : { top: 100 };

  return (
    <>
      <FAB
        style={{
          backgroundColor: TUNDORA,
          position: "absolute",
          ...estiloParaLados,
          ...estiloEspacaoParaCima,
          ...estiloBotao,
        }}
        small
        icon="home"
        onPress={() => acaoVoltar()}
      />
    </>
  );
};

export default BotaoIrParaTelaInicial;
