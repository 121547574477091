export const SLIDES = [
  {
    key: "0",
    title: "Sobre o aplicativo",
    text:
      "O Fiscalize App foi desenvolvido pela Diretoria de Apoio Logístico da Agefis no ano de 2016, tendo sua" +
      " segunda versão lançada em 2021. Tal sistema possui o objetivo de aproximar o cidadão e o Poder Público." +
      " Nesta plataforma, poderão ser registradas denúncias, que serão acompanhadas desde o registro inicial até a " +
      " conclusão da demanda pela Agefis.",
    image: require("../../../assets/icon.png"),
  },
  {
    key: "1",
    title: "Prefeitura de Fortaleza",
    text:
      "Fortaleza possui 314.930 km2 de área total e está dividida administrativamente em 12 Secretarias Executivas" +
      " Regionais.",
    image: require("../../../assets/logo-pmf.png"),
  },
  {
    key: "2",
    title: "Falando sobre a AGEFIS",
    text:
      "A Agência de Fiscalização de Fortaleza (Agefis) executa uma sistemática de gestão integrada, de modo a assegurar que o ciclo" +
      " completo da fiscalização (planejamento, gerenciamento, execução, processamento e monitoramento) seja realizado de forma coerente" +
      " e organizada na cidade.",
    image: require("../../../assets/agefis-logo.png"),
  },
];
