import React from "react";
import { View } from "react-native";
import { DARK_BLUE, WHITE } from "../../utils/cores";
import { ActivityIndicator, Dialog, Portal } from "react-native-paper";
import { Styles } from "../../styles/Styles";

function LoadingView() {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Portal>
        <Dialog
          visible={true}
          style={{
            backgroundColor: DARK_BLUE,
            height: "50%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 15,
            ...Styles.larguraWeb,
          }}
        >
          <Dialog.Content>
            <ActivityIndicator size={100} animating={true} color={WHITE} />
          </Dialog.Content>
        </Dialog>
      </Portal>
    </View>
  );
}

export default LoadingView;
