import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import React from "react";
import { Pressable, View } from "react-native";
import { HelperText, TextInput } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import { Styles } from "../../styles/Styles";
import { WHITE } from "../../utils/cores";

export const Input = React.forwardRef((props, forwardedRef) => {
  const {
    name,
    label,
    error,
    control,
    verSenha,
    rules,
    mudarVerSenha,
    ...textInputProps
  } = props;

  const isError = error !== "NAO" ? true : false;

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderBottomColor: WHITE,
          borderBottomWidth: 2,
        }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
              value={value}
              style={[Styles.containerInput, { flex: 11 }]}
              {...textInputProps}
              theme={{
                colors: {
                  placeholder: WHITE,
                  text: WHITE,
                  primary: WHITE,
                  underlineColor: "transparent",
                },
              }}
              label={label}
            />
          )}
          name={name}
          rules={rules}
          defaultValue=""
        />
        {mudarVerSenha && (
          <Pressable
            style={{ display: "flex", flex: 1, alignItems: "center" }}
            onPress={mudarVerSenha}
          >
            <FontAwesome
              name={verSenha ? "eye" : "eye-slash"}
              size={24}
              color={WHITE}
            />
          </Pressable>
        )}
      </View>
      {isError && rules?.required && (
        <HelperText type="error" visible={isError}>
          {error !== "SIM" ? error : "Este campo é obrigatório"}
        </HelperText>
      )}
    </View>
  );
});

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
};
