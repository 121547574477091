import React from "react";
import { Button } from "react-native-paper";
import CustomText from "../text/CustomText";
import { Styles } from "../../styles/Styles";
import { BLACK, SUPERNOVA, TUNDORA, WHITE } from "../../utils/cores";

function CustomButton(props) {
  return (
    <>
      {props.tipo === "CADASTRAR" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: WHITE }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={BLACK} isBold>
            CADASTRAR
          </CustomText>
        </Button>
      )}
      {props.tipo === "ACESSAR" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: TUNDORA }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            ACESSAR
          </CustomText>
        </Button>
      )}
      {props.tipo === "RECUPERAR_SENHA" && (
        <Button
          mode="outlined"
          style={{ width: "80%" }}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            RECUPERAR SENHA
          </CustomText>
        </Button>
      )}
      {props.tipo === "VOLTAR" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: WHITE }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={BLACK} isBold>
            VOLTAR
          </CustomText>
        </Button>
      )}
      {props.tipo === "PROXIMO" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: TUNDORA }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            PRÓXIMO
          </CustomText>
        </Button>
      )}
      {props.tipo === "GRAVAR" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: TUNDORA }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            {props.tituloBotao || "GRAVAR"}
          </CustomText>
        </Button>
      )}
      {props.tipo === "VOLTAR_BASE" && (
        <Button mode="outlined" onPress={props.acaoBotao}>
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            VOLTAR À TELA INICIAL
          </CustomText>
        </Button>
      )}
      {props.tipo === "ADICIONAR_ARQUIVO" && (
        <Button
          mode="contained"
          style={[Styles.padraoBotao, { backgroundColor: SUPERNOVA }]}
          onPress={props.acaoBotao}
        >
          <CustomText tamanhoFonte={15} customColor={BLACK} isBold>
            ADICIONAR ARQUIVO
          </CustomText>
        </Button>
      )}
      {props.tipo === "AJUSTAVEL" && (
        <Button
          mode="contained"
          style={[
            Styles.padraoBotao,
            { backgroundColor: props.corBotao || WHITE },
          ]}
          onPress={props.acaoBotao}
        >
          <CustomText
            tamanhoFonte={15}
            customColor={props.corTextoBotao || BLACK}
            isBold
          >
            {props.tituloBotao || "AÇÃO"}
          </CustomText>
        </Button>
      )}
    </>
  );
}

export default CustomButton;
