import { dadoExiste, nomeSistemaLongo } from "../../utils";
import { DARK_BLUE } from "../../utils/cores";
import CustomText from "./CustomText";
import { Linking, Pressable } from "react-native";

const propsCustomText = { tamanhoFonte: 16 };

const textoPadrao = (texto) => (
  <CustomText {...propsCustomText}>{texto || ""}</CustomText>
);

const textoEmNegrito = (texto) => (
  <CustomText {...propsCustomText} isBold>
    {texto || ""}
  </CustomText>
);

const textoEmLink = (texto, link) => (
  <Pressable
    onPress={async () =>
      dadoExiste(link) ? await Linking.openURL(link) : null
    }
  >
    <CustomText {...propsCustomText} customColor={DARK_BLUE}>
      {texto || ""}
    </CustomText>
  </Pressable>
);

const textoProdutoServico = `O sistema ${nomeSistemaLongo} tem por objetivo facilitar a apresentação de denúncias e o acompanhamento das respectivas providências adotadas, relativas aos serviços prestados pela Agência de Fiscalização - Agefis, com clareza e transparência nas informações, segurança e privacidade.

Pensando nisso, detalhamos abaixo nosso compromisso e respeito com a proteção e privacidade dos seus dados pessoais.
`;

const textoPorqueColetamosDados = `Seus dados pessoais são utilizados somente quando necessário, pelo tempo que for preciso e para viabilizar a prestação do serviço solicitado.

Eles são úteis para:
- Permitir a prestação dos serviços;
- Possibilitar o exercício dos seus direitos sobre seus dados;
- Personalizar a experiência de uso;
- Analisar e aperfeiçoar os serviços fornecidos;
- Respeitar exigências legais;
- Promover a cidadania e políticas de utilidade pública;
- Garantir a segurança e prevenir atividades ilegais.
`;

const quaisDadosColetados1 = `Abaixo, você verá quais dados são coletados pelo Aplicativo Fiscalize Fortaleza e como eles podem ser coletados:

Dados cadastrais do serviço gov.br
Para você ser identificado de forma segura e ter acesso aos serviços do Fiscalize Fortaleza,é utilizado o serviço “Acesso.gov.br”, que coleta os seguintes dados:
- Nome completo
- Telefone
- E-mail
- CPF
- Endereço
- Data de nascimento

Para mais informações, acessar o Termo de Uso do “Acesso.gov.br” em:`;

const quaisDadosColetados2 = `
Para o público que não possui o cadastro no serviço “Acesso.gov.br” nós coletamos os seguintes dados:
- Nome completo
- Telefone
- E-mail
- CPF
- Endereço
- Data de nascimento
`;

const conferenciaDados = `Solicitaremos e faremos uso apenas dos dados que são imprescindíveis e necessários para executar o serviço solicitado, e de acordo com a finalidade de cada serviço.

Eventualmente poderão ser utilizados dados da Receita Federal do Brasil (RFB) para conferir a integridade dos dados entre as bases governamentais, tais como:
- Nome completo
- Nome da mãe
- Data de nascimento
- CPF
`;

const dadoColetadosAutomaticamente = `Para aprimorar a usabilidade e navegabilidade do aplicativo, informações não pessoais podem ser coletadas automaticamente pelo dispositivo eletrônico utilizado (celular, tablet ou computador), pelo navegador ou por meio de cookies* ou outras ferramentas já instaladas no dispositivo do usuário. São elas:
- Modelo de hardware do dispositivo eletrônico utilizado pelo usuário;
- Sistema operacional e versão utilizados;
- Tipo de navegador e versão utilizada;
- Identificadores de dispositivos;
- Informações sobre a rede utilizada e o número de telefone;
- Idioma utilizado no dispositivo ou navegador;
- Resolução de tela;
- Identificadores exclusivos do aplicativo;
- Endereço de Protocolo de Internet (Endereço IP);
- Localização geográfica (geolocalização);
- Duração do acesso;
- Informações de identificador de SMS;
- Páginas visualizadas dentro do site;
- Forma de utilização do aplicativo;
`;

const comoArmazenamosDados = `Todos os dados coletados são armazenados, pelo tempo necessário, em centros de dados sob a gestão da Prefeitura de Fortaleza.
As informações trafegam de modo criptografado entre o seu dispositivo e os demais equipamentos que se integram à solução com medidas que assegurem a confidencialidade, integridade, autenticidade e inviolabilidade dos seus dados. 

O acesso aos dados coletados está restrito a colaboradores autorizados para o uso adequado desses dados, incluindo servidores públicos e terceirizados. Aqueles que utilizarem indevidamente essas informações, ferindo nossa Política de Privacidade e demais políticas internas, estarão sujeitas às penalidades previstas em lei.

Será exigida de toda empresa ou instituição associada ou contratada para prover serviços de apoio, o cumprimento aos nossos padrões de privacidade e segurança da informação.

A partir do momento que seus dados não tiverem mais utilidade ou o titular dos dados solicitar sua eliminação, eles serão automaticamente eliminados para garantir a proteção da sua privacidade. Ainda assim, seus dados pessoais poderão ser mantidos para cumprimento de obrigação legal ou regulatória.
`;

const compartilhamentoInfoTerceiros = `Seus dados de caráter pessoal não serão repassados a terceiros, nem pela Agência de Fiscalização de Fortaleza, Autarquia Municipal, nem pela Prefeitura de Fortaleza. Todo e qualquer dado pessoal só poderá ser repassado mediante sua autorização, exceto quando exigido por lei.
`;

const cookies = `Nós usamos cookies* quando você interage com o Aplicativo Fiscalize Fortaleza via navegador. Os dados de cookies* de sessão são excluídos assim que você deixa o a página do Aplicativo. Outros dados de cookies* são armazenados para facilitar seu acesso, reconhecendo você ao retornar para a página do Fiscalize Fortaleza.

Seu consentimento quanto à utilização dos cookies* pode a qualquer momento ser alterado e, inclusive, recusado. É importante saber que, ao retirar o consentimento de utilização de cookies*, o funcionamento de determinadas funcionalidades estarão indisponíveis prejudicando uma melhor experiência com a aplicação.
`;

const retencaoInfoColetadas = `A partir do momento que seus dados não tiverem mais utilidade ou o titular dos dados solicitar sua eliminação, eles serão automaticamente eliminados para garantir a proteção da sua privacidade.
Ainda assim, seus dados pessoais poderão ser mantidos para cumprimento de obrigação legal ou regulatória.
`;

const atualizacaoPolitica = `Para estar de acordo com a Lei Geral de Proteção de Dados (LGPD), seus dados serão coletados, armazenados e tratados apenas com o seu consentimento. É importante estar claro que, caso não aprove ou cancele um consentimento para utilização dos seus dados pessoais, isto poderá impossibilitar o funcionamento adequado de uma funcionalidade ou a execução de um serviço que dependa destes dados.

Ao fornecer seus dados pessoais para utilização do Aplicativo Fiscalize Fortaleza você estará de acordo com o conteúdo desta Política de Privacidade.
Esta política pode ser alterada a qualquer momento para atender à evolução do aplicativo ou à Lei Geral de Proteção de Dados (LGPD). Por isso, recomendamos que volte a ler este termo periodicamente.

*Cookie: arquivo colocado em seu computador para rastrear movimentos dentro dos websites, como visitas a páginas e a anúncios. Cookies* não armazenam informações pessoais sem que você as tenha fornecido e não coletam informações registradas em seu computador.`;

const TextoPoliticaPrivacidade = () => {
  return (
    <>
      {textoEmNegrito("Produto e Serviço\n")}
      {textoPadrao(textoProdutoServico)}
      {textoEmNegrito("\nPor que coletamos seus dados pessoais\n")}
      {textoPadrao(textoPorqueColetamosDados)}
      {textoEmNegrito("\nQuais dados são coletados e para qual finalidade\n")}
      {textoPadrao(quaisDadosColetados1)}
      {textoEmLink(
        ` "O que autorizo de dados?" `,
        "http://faq-login-unico.servicos.gov.br/en/latest/_perguntasdafaq/oqueeautorizacaodeusodedados.html"
      )}
      {textoPadrao(quaisDadosColetados2)}
      {textoEmNegrito(
        "\nConferência dos dados pessoais fornecidos pelo titular dos dados\n"
      )}
      {textoPadrao(conferenciaDados)}
      {textoEmNegrito("\nDados coletados automaticamente pelo dispositivo\n")}
      {textoPadrao(dadoColetadosAutomaticamente)}
      {textoEmNegrito("\nComo os dados são armazenados\n")}
      {textoPadrao(comoArmazenamosDados)}
      {textoEmNegrito("\nCompartilhamento de informações com Terceiros\n")}
      {textoPadrao(compartilhamentoInfoTerceiros)}
      {textoEmNegrito("\nCookies\n")}
      {textoPadrao(cookies)}
      {textoEmNegrito("\nRetenção das informações coletadas\n")}
      {textoPadrao(retencaoInfoColetadas)}
      {textoEmNegrito("\nAtualização da Política e Consentimento\n")}
      {textoPadrao(atualizacaoPolitica)}
    </>
  );
};

export default TextoPoliticaPrivacidade;
