import Jack from "../../assets/getec/Jack.jpg";
import Vinicius from "../../assets/getec/Vinicius.jpg";

export const TIME_AGEFIS = [
  {
    nome: "Jackson Torres",
    cargo: "Líder Técnico",
    Linkedin: "@jacktorresr",
    linkLinkedin: "https://www.linkedin.com/in/jacktorresr/",
    imagem: Jack,
  },
  {
    nome: "Vinicius Zanoni",
    cargo: "Full-stack DEV",
    Linkedin: "@vinicius.zanoni",
    linkLinkedin: "https://www.linkedin.com/in/vinicius-zanoni/",
    imagem: Vinicius,
  },
];
