import React from "react";
import { StyleSheet, Pressable } from "react-native";

import CustomText from "../text/CustomText";
import { Styles } from "../../styles/Styles";
import { BLACK, WHITE } from "../../utils/cores";

function BotaoDrawer(props) {
  return (
    <>
      <Pressable
        onPress={props.acaoBotao}
        style={[styles.containerBotao, Styles.padraoBotao]}
      >
        {props.icone}
        <CustomText
          style={{ marginLeft: 10 }}
          taamnhoFonte={30}
          customColor={BLACK}
          isBold
        >
          {props.tituloBotao}
        </CustomText>
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  containerBotao: {
    backgroundColor: WHITE,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
});

export default BotaoDrawer;
