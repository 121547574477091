import { errorHandler, toastr } from "../utils/messages";
import store from "../redux/store";
import axiosClient from "../utils/axiosClient";
import { dadoExiste, validarCPFouCNPJ } from "../utils";
import { navigate } from "../utils/navigationService";

const urlPrincipal = "autos-infracoes";

export const getMinhasInfracoes = (params = {}) => {
  store.dispatch({ type: "loading/loadingStart" });
  const url = `/${urlPrincipal}/minhas-infracoes`;
  axiosClient
    .get(url, { params })
    .then((resp) => {
      store.dispatch({ type: "auto/AUTO_LISTED", payload: resp.data?.list });
      store.dispatch({ type: "loading/loadingStop" });
    })
    .catch((e) => {
      store.dispatch({ type: "loading/loadingStop" });
      errorHandler(e);
    });
};

export const getDetalheInfracao = (params = {}) => {
  let dados = { ...params };
  const { cpfCnpj, numeroDocumento, serie } = dados;

  if (!dadoExiste(cpfCnpj)) {
    toastr.warning("CPF/CNPJ não informado!");
    return null;
  }

  if (!dadoExiste(numeroDocumento)) {
    toastr.warning("Número de documento não informado!");
    return null;
  }

  if (!dadoExiste(serie)) {
    toastr.warning("Série não informada!");
    return null;
  }

  const erroValidacaoCpfCNPJ = validarCPFouCNPJ(cpfCnpj);
  if (dadoExiste(erroValidacaoCpfCNPJ)) {
    toastr.warning(erroValidacaoCpfCNPJ);
    return null;
  }
  const numeroDocumentoValido =
    dadoExiste(numeroDocumento) &&
    /^\d+$/.test(numeroDocumento?.toString()?.trim());

  if (!numeroDocumentoValido) {
    toastr.warning("O número do documento deve conter apenas números!");
    return null;
  }

  delete dados.numeroDocumento;
  delete dados.serie;
  dados = {
    ...dados,
    cpfCnpj: cpfCnpj?.replace(/[^\d]/g, ""),
    numeroDocumentoComSerie: numeroDocumento?.trim() + `-${serie}`,
  };

  store.dispatch({ type: "loading/loadingStart" });
  const url = `/${urlPrincipal}/detalhe-infracao`;
  axiosClient
    .get(url, { params: dados })
    .then((resp) => {
      store.dispatch({ type: "auto/AUTO_DETAIL", payload: resp.data });
      store.dispatch({ type: "loading/loadingStop" });
      navigate("DetalheMinhaInfracao", { autoId: resp.data.id });
    })
    .catch((e) => {
      store.dispatch({ type: "loading/loadingStop" });
      errorHandler(e);
    });
};
