import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { View } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import * as SecureStore from "expo-secure-store";
import consts from "../config";

import Base from "../Base";
import Sobre from "../views/sobre/Sobre";
import Login from "../views/login/Login";
import SignUp from "../views/login/SignUp";
import ResetPassword from "../views/login/ResetPassword";
import InserirCodigo from "../views/login/InserirCodigo";
import MinhasDenuncias from "../views/denuncias/MinhasDenuncias";
import DetalheMinhasDenuncias from "../views/denuncias/DetalheMinhasDenuncias";
import CadastrarDenuncia from "../views/denuncias/CadastrarDenuncia";
import ConsultarDenuncia from "../views/denuncias/ConsultarDenuncia";
import LoadingView from "../components/visualizacao/LoadingView";

import Step2Fiscalizado from "../views/denuncias/steps-cadastro/Step2Fiscalizado";
import Localizacao from "../views/denuncias/steps-cadastro/Localizacao";
import CadastroTurnosDiasFuncionamento from "../views/denuncias/steps-cadastro/CadastroTurnosDiasFuncionamento";
import DataHoraDenuncia from "../views/denuncias/steps-cadastro/DataHoraDenuncia";
import DescricaoDenuncia from "../views/denuncias/steps-cadastro/DescricaoDenuncia";
import Resumo from "../views/denuncias/steps-cadastro/Resumo";
import CardMenuDrawer from "../components/card/CardMenuDrawer";
import { checkLoginUnico, login } from "../api/auth";
import AvisoView from "../components/visualizacao/AvisoView";
import { Styles } from "../styles/Styles";
import { eAplicativo } from ".";
import DesativacaoConta from "../views/DesativacaoConta/DesativacaoConta";
import PoliticaPrivacidade from "../views/PoliticaPrivacidade/PoliticaPrivacidade";
import InformativoDescontos from "../views/InformativoDescontos/InformativoDescontos";
import DetalheMinhaInfracao from "../views/Auto/DetalheMinhaInfracao";
import MinhasInfracoes from "../views/Auto/MinhasInfracoes";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

function NavegacaoDrawer() {
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerType: "back",
        headerShown: false,
        drawerHideStatusBarOnOpen: true,
        drawerStatusBarAnimation: "slide",
      }}
      drawerContent={(props) => <CardMenuDrawer {...props} />}
      initialRouteName="Base"
    >
      <Drawer.Screen name="Base" component={Base} />
    </Drawer.Navigator>
  );
}

function NavegacaoStack() {
  const appReady = useSelector((state) => state.auth.appReady);
  const validToken = useSelector((state) => state.auth.validToken);
  const isLoading = useSelector((state) => state.loading?.isLoading);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      const desconectar = async () => {
        if (eAplicativo) {
          await SecureStore.deleteItemAsync(consts.USER_KEY);
        }
        dispatch({ type: "auth/userLogout" });
        dispatch({ type: "auth/appReady" });
      };

      if (eAplicativo) {
        try {
          let user = await SecureStore.getItemAsync(consts.USER_KEY);
          const usuarioJson = JSON.parse(user);
          if (usuarioJson?.token !== undefined && usuarioJson?.token !== null) {
            login({ email: usuarioJson?.email, senha: usuarioJson?.senha });
          } else {
            desconectar();
          }
        } catch (e) {
          desconectar();
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get("code");

        const urlSite = window.location.href;
        const temTelaParaIniciar = urlSite?.includes("#/");

        if (temTelaParaIniciar) {
          const iniciarNaTela = urlSite?.slice(urlSite?.indexOf("#/"));
          const telaInicialAtual = localStorage.getItem(
            consts.TELA_INICIAL_KEY
          );

          const telasDiferentes = iniciarNaTela !== telaInicialAtual;
          if (telasDiferentes) {
            localStorage.setItem(consts.TELA_INICIAL_KEY, iniciarNaTela);
          }

        }

        if (code?.includes("#/")) {
          code = code.slice(0, code.indexOf("#/"));
        }

        if (code) {
          checkLoginUnico(code);
          return null;
        }

        const usuario = localStorage?.getItem(consts.USER_KEY) || null;
        const temUsuarioLocal = usuario !== null && usuario !== "undefined";
        if (temUsuarioLocal) {
          const usuarioJson = JSON.parse(usuario);
          login({ email: usuarioJson?.email, senha: usuarioJson?.senha });
          return null;
        }

        desconectar();
      }
    };

    bootstrapAsync();
  }, []);
  if (appReady === false && eAplicativo)
    return (
      <>
        {!isLoading && (
          <View style={Styles.containerPrincipal}>
            <AvisoView />
          </View>
        )}
      </>
    );

  const caminho = !eAplicativo && window.location.href;
  const telaPoliticaPrivacidade =
    !eAplicativo && caminho?.includes("politica-privacidade");

  const telaInformativoDescontosDam =
    !eAplicativo && caminho?.includes("informativo-descontos");

  const telaMinhaInfracao = !eAplicativo && caminho?.includes("minha-infracao");

  const naoEstaEmTelaCustomizada =
    !telaPoliticaPrivacidade &&
    !telaInformativoDescontosDam &&
    !telaMinhaInfracao;

  if (validToken === false) {
    return (
      <>
        {isLoading && <LoadingView />}
        <Stack.Navigator>
          {telaPoliticaPrivacidade && (
            <Stack.Screen
              name="PoliticaPrivacidade"
              component={PoliticaPrivacidade}
              options={{ headerShown: false }}
            />
          )}
          {telaInformativoDescontosDam && (
            <Stack.Screen
              name="InformativoDescontos"
              component={InformativoDescontos}
              options={{ headerShown: false }}
            />
          )}
          {telaMinhaInfracao && (
            <>
              <Stack.Screen
                name="MinhasInfracoes"
                component={MinhasInfracoes}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="DetalheMinhaInfracao"
                component={DetalheMinhaInfracao}
                options={{ headerShown: false }}
              />
            </>
          )}
          {naoEstaEmTelaCustomizada && (
            <>
              <Stack.Screen
                name="Login"
                component={Login}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUp}
                options={{
                  headerShown: false,
                  title: "Cadastro de novo usuário",
                  headerTitleAlign: "center",
                }}
              />
              <Stack.Screen
                name="ResetPassword"
                component={ResetPassword}
                options={{
                  headerShown: false,
                  title: "Recuperar senha",
                  headerTitleAlign: "center",
                }}
              />
              <Stack.Screen
                name="InserirCodigo"
                component={InserirCodigo}
                options={{
                  headerShown: false,
                  title: "Inserir nova senha",
                  headerTitleAlign: "center",
                }}
              />
            </>
          )}
        </Stack.Navigator>
      </>
    );
  }

  return (
    <>
      {isLoading && <LoadingView />}
      <Stack.Navigator>
        <Stack.Screen
          name="Base"
          component={NavegacaoDrawer}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Sobre"
          component={Sobre}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CadastrarDenuncia"
          component={CadastrarDenuncia}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Step2Fiscalizado"
          component={Step2Fiscalizado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Localizacao"
          component={Localizacao}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CadastroTurnosDiasFuncionamento"
          component={CadastroTurnosDiasFuncionamento}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DataHoraDenuncia"
          component={DataHoraDenuncia}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DescricaoDenuncia"
          component={DescricaoDenuncia}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Resumo"
          component={Resumo}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ConsultarDenuncia"
          component={ConsultarDenuncia}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DetalheMinhasDenuncias"
          component={DetalheMinhasDenuncias}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MinhasDenuncias"
          component={MinhasDenuncias}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MinhasInfracoes"
          component={MinhasInfracoes}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DetalheMinhaInfracao"
          component={DetalheMinhaInfracao}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DesativacaoConta"
          component={DesativacaoConta}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="PoliticaPrivacidade"
          component={PoliticaPrivacidade}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InformativoDescontos"
          component={InformativoDescontos}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </>
  );
}

export default NavegacaoStack;
