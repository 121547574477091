export const GRUPOS_FISCALIZACAO = [
  {
    nome: "Poluição Sonora",
    descricao: "POLUIÇÃO SONORA",
    fundomunicipal_id: "5",
    id: "15472",
    ativo: true,
  },
  {
    nome: "Obras, edificações, intervenções e ocupações irregulares",
    descricao: "Infrações relacionadas a Obras no Código da Cidade",
    fundomunicipal_id: "3",
    id: "102749",
    ativo: true,
  },
  {
    nome: "Posturas e práticas inadequadas",
    descricao: "Posturas e práticas inadequadas ",
    fundomunicipal_id: "3",
    id: "103168",
    ativo: true,
  },
  {
    nome: "Poluição Hídrica",
    descricao: "POLUIÇÃO HÍDRICA",
    fundomunicipal_id: "4",
    id: "15479",
    ativo: true,
  },
  {
    nome: "Poluição Atmosférica",
    descricao: "POLUIÇÃO ATMOSFÉRICA",
    fundomunicipal_id: "2",
    id: "15474",
    ativo: true,
  },
  {
    nome: "Poluição do Solo",
    descricao: "POLUIÇÃO DO SOLO",
    fundomunicipal_id: "2",
    id: "15481",
    ativo: true,
  },
  {
    nome: "Poluição Visual",
    descricao: "POLUIÇÃO VISUAL",
    fundomunicipal_id: "2",
    id: "15476",
    ativo: true,
  },
  {
    nome: "Infração Ambiental",
    descricao: "Infração Ambienta - Denúncia",
    fundomunicipal_id: "2",
    id: "61881",
    ativo: true,
  },
  {
    nome: "Comércio Ambulante, Feiras e permissionários",
    descricao: "Comércio Ambulante, Feiras e permissionários - Denúncia",
    fundomunicipal_id: "6",
    id: "107728",
    ativo: true,
  },
  {
    nome: "Defesa do Consumidor",
    descricao: "DEFESA DO CONSUMIDOR - DENUNCIA",
    fundomunicipal_id: "9",
    id: "16144",
    ativo: true,
  },
  {
    nome: "Resíduos Sólidos",
    descricao: "Resíduos Sólidos - Denúncia",
    fundomunicipal_id: "7",
    id: "7",
    ativo: true,
  },
  {
    nome: "Patrimônio Histórico e Cultural",
    descricao: "Patrimônio Histórico e Cultural – Denúncia",
    fundomunicipal_id: "10",
    id: "107818",
    ativo: true,
  },
  {
    nome: "Vigilância Sanitária",
    descricao: "Vigilância sanitária - Denúncia",
    fundomunicipal_id: "8",
    id: "9",
    ativo: true,
  },
];
