import React from "react";
import { View } from "react-native";
import CustomText from "../text/CustomText";
import { Styles } from "../../styles/Styles";
import { WHITE } from "../../utils/cores";

function Copyright(props) {
  return (
    <View style={Styles.containerRodape}>
      <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
        {`© ${new Date().getFullYear()} Prefeitura de Fortaleza,`}
      </CustomText>
      <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
        {`AGEFIS, DIALOG/GETEC`}
      </CustomText>
    </View>
  );
}

export default Copyright;
