import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { useForm } from "react-hook-form";
import { ProgressBar, Colors, Switch } from "react-native-paper";
import { useDispatch } from "react-redux";
import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import {
  DIAS_FUNCIONAMENTO,
  TURNOS,
} from "../../../redux/slices/cadastroDenuncia";
import { objCheckboxToArray } from "../../../utils";
import CardGenerator from "../../../components/card/CardGenerator";
import CustomButton from "../../../components/buttons/CustomButton";
import {
  BLACK,
  KEPPEL,
  PICTON_BLUE,
  TUNDORA,
  WHITE,
} from "../../../utils/cores";
import { toastr } from "../../../utils/messages";

function CadastroTurnosDiasFuncionamento({ navigation }) {
  const dispatch = useDispatch();
  const [turnos, setTurnos] = useState({});
  const [diasFuncionamento, setDiasFuncionamento] = useState({});
  let stringTurnos = objCheckboxToArray(turnos);
  let stringDiasFuncionamento = objCheckboxToArray(diasFuncionamento);

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    if (stringDiasFuncionamento?.length <= 0 && stringTurnos?.length <= 0) {
      toastr.warning("Escolha um turno de funcionamento, por favor");
    } else if (stringDiasFuncionamento?.length <= 0) {
      toastr.warning("Escolha um dia de funcionamento, por favor");
    } else if (stringTurnos?.length <= 0) {
      toastr.warning(
        "Escolha, no mínimo, um turno de funcionamento, por favor"
      );
    } else {
      const payloadData = {
        turnos: stringTurnos,
        diasFuncionamento: stringDiasFuncionamento,
      };
      dispatch({
        type: "cadastroDenuncia/cadastroTurnosDiasFuncionamento",
        payload: payloadData,
      });
      navigation.navigate("DataHoraDenuncia");
    }
  };

  const estiloContainerSwitches = {
    display: "flex",
    alignItems: "center",
    margin: 5,
    flexDirection: "row",
    flexWrap: "wrap",
    minWidth: 150,
  };

  return (
    <ScrollView style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo={`CADASTRO DO FUNCIONAMENTO`} />
      </View>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={0.5}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <>
          <CardGenerator
            semEspacoEntreCards
            alinhamentoTexto="center"
            tipo={"TEXTO_APENAS"}
            texto={"DIAS DE FUNCIONAMENTO"}
          />
          <CardGenerator
            semEspacoEntreCards
            tipo={"TEXTO_APENAS"}
            texto={
              <>
                {DIAS_FUNCIONAMENTO.map((df, key) => (
                  <View key={key} style={estiloContainerSwitches}>
                    <Switch
                      style={{ margin: 5 }}
                      trackColor={{ true: PICTON_BLUE, false: KEPPEL }}
                      thumbColor={diasFuncionamento[df.value] ? WHITE : TUNDORA}
                      value={diasFuncionamento[df.value] ? true : false}
                      onValueChange={() =>
                        setDiasFuncionamento({
                          ...diasFuncionamento,
                          [df.value]: !diasFuncionamento[df.value],
                        })
                      }
                    />
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={15}
                      customColor={BLACK}
                      isBold
                    >
                      {df.label}
                    </CustomText>
                  </View>
                ))}
              </>
            }
          />
          <CardGenerator
            alinhamentoTexto="center"
            tipo={"TEXTO_APENAS"}
            texto={"TURNOS DE FUNCIONAMENTO"}
          />
          <CardGenerator
            semEspacoEntreCards
            tipo={"TEXTO_APENAS"}
            texto={
              <>
                {TURNOS.map((t, key) => (
                  <View key={key} style={estiloContainerSwitches}>
                    <Switch
                      style={{ margin: 5 }}
                      trackColor={{ true: PICTON_BLUE, false: KEPPEL }}
                      thumbColor={turnos[t.value] ? WHITE : TUNDORA}
                      value={turnos[t.value] ? true : false}
                      onValueChange={() =>
                        setTurnos({
                          ...turnos,
                          [t.value]: !turnos[t.value],
                        })
                      }
                    />
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={15}
                      customColor={BLACK}
                      isBold
                    >
                      {t.label}
                    </CustomText>
                  </View>
                ))}
              </>
            }
          />
        </>
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="PROXIMO" acaoBotao={handleSubmit(onSubmit)} />
        </View>
      </View>
    </ScrollView>
  );
}

export default CadastroTurnosDiasFuncionamento;
