import React from "react";
import { ScrollView, View } from "react-native";
import { Styles } from "../../styles/Styles";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";
import LogoView from "../../components/visualizacao/LogoView";
import { getDetalheInfracao } from "../../api/autos";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  dadoExiste,
  getStringEndereco,
  textoNumeroDocumentoComSerie,
} from "../../utils";
import CardGenerator from "../../components/card/CardGenerator";
import { BLACK } from "../../utils/cores";

const DetalheMinhaInfracao = ({ route, navigation }) => {
  const detalheInfracao = useSelector((state) => state.auto?.detail || {});

  const { autoId } = route?.params;

  const { error } = useQuery(
    "fetchDetalheInfracao",
    () => getDetalheInfracao(route?.params),
    { enabled: detalheInfracao?.id !== autoId }
  );

  const { dataConstatacao } = detalheInfracao;

  const numeroDocumentoFiscal = textoNumeroDocumentoComSerie(detalheInfracao);

  const arquivosAuto = detalheInfracao?.arquivos || [];

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo={"DETALHE DA INFRAÇÃO"} comLogoAgefis />
      </View>
      <BotaoVoltar />
      {!dadoExiste(error) && (
        <ScrollView scrollIndicatorInsets={{ right: 1 }}>
          {dadoExiste(detalheInfracao?.id) && (
            <View style={Styles.larguraWeb}>
              <View style={Styles.containerLogo}>
                <CardGenerator
                  semEspacoEntreCards
                  style={{ alignSelf: "center" }}
                  tipo={"TEXTO_APENAS"}
                  corTexto={BLACK}
                  tamanhoTexto={15}
                  texto={`DATA CRIAÇÃO: ${detalheInfracao?.dataCriacao}`}
                />
                <CardGenerator
                  semEspacoEntreCards
                  alinhamentoTexto="left"
                  tipo={"TEXTO_APENAS"}
                  tamanhoTexto={15}
                  texto={
                    <>
                      {dadoExiste(numeroDocumentoFiscal) &&
                        `Nº DOCUMENTO FISCAL:\n${numeroDocumentoFiscal}`}
                      {dadoExiste(dataConstatacao) &&
                        `\n\nData constatação:\n${dataConstatacao}`}
                      {dadoExiste(getStringEndereco(detalheInfracao)) &&
                        `\n\nENDEREÇO DA INFRAÇÃO:\n${getStringEndereco(
                          detalheInfracao
                        )}`}
                    </>
                  }
                />
              </View>
              {arquivosAuto?.length > 0 ? (
                <CardGenerator
                  semEspacoEntreCards
                  tipo={"DISPLAY_ARQUIVOS"}
                  exibirBotaoExcluir={false}
                  exibirVisualizarArquivo
                  corTexto={BLACK}
                  tamanhoTexto={15}
                  arquivos={detalheInfracao?.arquivos}
                  exibirBotaoAdicionar={false}
                  denunciaId={detalheInfracao?.id}
                />
              ) : (
                <CardGenerator
                  semEspacoEntreCards
                  alinhamentoTexto="left"
                  tipo={"TEXTO_APENAS"}
                  tamanhoTexto={15}
                  texto={"VIDEO NÃO LOCALIZADO!"}
                />
              )}
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default DetalheMinhaInfracao;
