import store from "../redux/store";
import axiosClient from "../utils/axiosClient";
import { errorHandler } from "../utils/messages";

export function listarBairros() {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .get(`/bairros/listar-app?order=nome&limit=-1`)
      .then((res) => {
        store.dispatch({ type: "bairros/BAIRROS_LISTED", payload: res.data });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}
