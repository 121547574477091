import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { register } from "../../api/auth";
import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import CustomButton from "../../components/buttons/CustomButton";
import { Input } from "../../components/form/Input";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const SignUp = ({ navigation }) => {
  const [verSenha, setVerSenha] = useState(false);

  const schema = yup.object().shape({
    nome: yup
      .string()
      .matches(/^([^0-9]*)$/, "O nome não deve conter números")
      .required("O campo de nome deve ser preenchido"),
    email: yup
      .string()
      .email("Por favor digite um e-mail válido")
      .required("O campo de e-mail deve ser preenchido"),
    senha: yup
      .string()
      .min(5, "A senha deve ter no mínimo 5 caracteres")
      .required("O campo de senha deve ser preenchido"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => register(data);

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      scrollIndicatorInsets={{ right: 1 }}
      style={Styles.containerPrincipal}
    >
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="CADASTRO PESSOAL" />
      </View>
      <View style={Styles.larguraWeb}>
        <Input
          control={control}
          name="nome"
          label="Nome"
          rules={{ required: true }}
          error={errors?.nome === undefined ? "NAO" : errors?.nome?.message}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <Input
          control={control}
          name="email"
          label="Email"
          rules={{ required: true }}
          autoCapitalize="none"
          keyboardType="email-address"
          error={errors?.email === undefined ? "NAO" : errors?.email?.message}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <Input
          control={control}
          name="senha"
          label="Senha"
          rules={{ required: true }}
          error={errors?.senha === undefined ? "NAO" : errors?.senha?.message}
          secureTextEntry={verSenha ? false : true}
          verSenha={verSenha}
          mudarVerSenha={() => setVerSenha(!verSenha)}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <View style={Styles.containerBotoesDuplos}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="GRAVAR" acaoBotao={handleSubmit(onSubmit)} />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default SignUp;
