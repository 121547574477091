import React from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { TUNDORA, WHITE } from "../../utils/cores";

import CustomText from "../text/CustomText";

function CardBotaoComIcon(props) {
  return (
    <>
      <Pressable onPress={props.onPress} style={styles.cardContainer}>
        <View style={styles.cardIconContainer}>{props.iconComponent}</View>
        <View style={styles.cardTitleContainer}>
          <CustomText
            style={{ textAlign: "center" }}
            customColor={WHITE}
            isBold
          >
            {props.buttonText}
          </CustomText>
        </View>
      </Pressable>
    </>
  );
}

const tamanhoBorda = 10;

const styles = StyleSheet.create({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
    width: 160,
    borderRadius: tamanhoBorda,
  },
  cardIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: WHITE,
    borderTopStartRadius: tamanhoBorda,
    borderTopEndRadius: tamanhoBorda,
    height: 90,
    width: "100%",
  },
  cardTitleContainer: {
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: TUNDORA,
    borderBottomStartRadius: tamanhoBorda,
    borderBottomEndRadius: tamanhoBorda,
    padding: 10,
    height: 90,
    width: "100%",
  },
});

export default CardBotaoComIcon;
