import React from "react";
import { useForm } from "react-hook-form";
import { View } from "react-native";
import { passwordRecover } from "../../api/auth";
import { Styles } from "../../styles/Styles";
import CustomText from "../../components/text/CustomText";
import LogoView from "../../components/visualizacao/LogoView";
import CustomButton from "../../components/buttons/CustomButton";
import { Input } from "../../components/form/Input";
import { WHITE } from "../../utils/cores";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const ResetPassword = ({ navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => passwordRecover(data);

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      scrollIndicatorInsets={{ right: 1 }}
      style={Styles.containerPrincipal}
    >
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="SOLICITAÇÃO DE NOVA SENHA" />
      </View>
      <View style={Styles.larguraWeb}>
        <View style={{ margin: 5 }}>
          <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
            Insira seu e-mail
          </CustomText>
        </View>
        <Input
          control={control}
          name="email"
          label="Email"
          rules={{ required: true }}
          autoCapitalize="none"
          keyboardType="email-address"
          error={errors?.email === undefined ? "NAO" : "SIM"}
          returnKeyType="done"
          onSubmitEditing={handleSubmit(onSubmit)}
        />
        <View style={Styles.containerBotoesDuplos}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton
            tipo="GRAVAR"
            tituloBotao="ENVIAR CÓDIGO"
            acaoBotao={handleSubmit(onSubmit)}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default ResetPassword;
