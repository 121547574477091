import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { View, Alert } from "react-native";
import { TextInput } from "react-native-paper";
import { useSelector } from "react-redux";
import { toastr } from "../../utils/messages";
import { passwordRecoverCode, validarCodigo } from "../../api/auth";
import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import CustomButton from "../../components/buttons/CustomButton";
import { Input } from "../../components/form/Input";
import { WHITE } from "../../utils/cores";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const InserirCodigo = ({ route, navigation }) => {
  const { email } = route.params;
  const [codigo, setCodigo] = useState("");
  const [verSenha, setVerSenha] = useState(false);
  const codigoReconhecido = useSelector(
    (state) => state?.auth?.codigoResetValido
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const onSubmit = (data) => {
    if (watch("senha")?.length < 5) {
      toastr.warning("A senha precisar ser de 5 ou mais caracteres!");
      return;
    }
    const payloadData = { ...data, email, codigo };
    passwordRecoverCode(payloadData);
  };

  const verificarCodigo = () => {
    if (codigo === "") {
      toastr.warning("Você precisa escrever o código para ser verificado!");
      return;
    }
    validarCodigo({ email, codigo: codigo });
  };

  useEffect(() => {
    Alert.alert("Atenção", "O código foi enviado ao seu e-mail");
  }, []);

  return (
    <KeyboardAwareScrollView
      extraHeight={130}
      scrollIndicatorInsets={{ right: 1 }}
      style={Styles.containerPrincipal}
    >
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="ATUALIZAÇÃO DE SENHA" />
      </View>
      <View style={Styles.larguraWeb}>
        {codigoReconhecido === false && (
          <>
            <TextInput
              onChangeText={(value) => setCodigo(value)}
              value={codigo}
              style={[
                Styles.containerInput,
                {
                  flex: 11,
                  width: "95%",
                },
              ]}
              multiline
              returnKeyType="done"
              onSubmitEditing={() => verificarCodigo()}
              theme={{
                colors: {
                  placeholder: WHITE,
                  text: WHITE,
                  primary: WHITE,
                  underlineColor: "transparent",
                },
              }}
              label={"Digite o código"}
            />
            <View style={Styles.containerBotoesDuplos}>
              <CustomButton
                tipo="VOLTAR"
                acaoBotao={() => navigation.goBack()}
              />
              <CustomButton
                tipo="GRAVAR"
                tituloBotao="VALIDAR"
                acaoBotao={() => verificarCodigo()}
              />
            </View>
          </>
        )}
        {codigoReconhecido && (
          <>
            <Input
              control={control}
              name="senha"
              label="Nova senha"
              rules={{ required: true }}
              error={errors?.senha === undefined ? "NAO" : "SIM"}
              secureTextEntry={verSenha ? false : true}
              verSenha={verSenha}
              mudarVerSenha={() => setVerSenha(!verSenha)}
              returnKeyType="done"
              onSubmitEditing={handleSubmit(onSubmit)}
            />
            <View style={Styles.containerBotoesDuplos}>
              <CustomButton
                tipo="VOLTAR"
                acaoBotao={() => navigation.goBack()}
              />
              <CustomButton tipo="GRAVAR" acaoBotao={handleSubmit(onSubmit)} />
            </View>
          </>
        )}
      </View>
    </KeyboardAwareScrollView>
  );
};

export default InserirCodigo;
