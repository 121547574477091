import { createSlice } from "@reduxjs/toolkit";

export const ChatReducer = createSlice({
  name: "chat",
  initialState: {
    mensagens: [],
    qtdNotificacoesMensagens: null,
  },
  reducers: {
    mensagensRecebidas: (state, action) => {
      state.mensagens = action.payload.list;
    },
    limparMensagens: (state) => {
      state.mensagens = [];
    },
    notificacoesRecebidas: (state, action) => {
      state.qtdNotificacoesMensagens = action.payload;
    },
  },
});

export const { mensagens, qtdNotificacoesMensagens } = ChatReducer.actions;
export default ChatReducer.reducer;
