import React, { useRef } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

import { Styles } from "./styles/Styles";
import CustomText from "./components/text/CustomText";
import { ATOLL, BLACK, KEPPEL, SUPERNOVA, WHITE } from "./utils/cores";

function ChatDenuncia(props) {
  const { dados } = props;
  const scrollViewRef = useRef();

  return (
    <>
      <Pressable
        style={[
          {
            backgroundColor: KEPPEL,
            padding: 10,
            width: 50,
            height: 50,
            display: "flex",
            alignSelf: "flex-end",
          },
          Styles.botaoIcone,
        ]}
        onPress={props.fecharModal}
      >
        <FontAwesome name="close" size={24} color={BLACK} />
      </Pressable>
      <View style={{ alignItems: "center" }}>
        <CustomText tamanhoFonte={24} customColor={BLACK} isBold>
          Notificações
        </CustomText>
        <CustomText
          style={{ textAlign: "center" }}
          tamanhoFonte={16}
          customColor={WHITE}
          isBold
        >
          Verifique as novas mensagens dessas denúncias, clique para mais
          detalhes.
        </CustomText>
      </View>
      <ScrollView
        style={{ marginBottom: 10, marginTop: 10 }}
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: true })
        }
      >
        {dados?.map((notificacao, key) => {
          return (
            <Pressable
              key={"PRINCIPAL-" + key}
              style={[
                Styles.containerCard,
                { backgroundColor: key % 2 === 0 ? KEPPEL : ATOLL },
              ]}
              onPress={() => {
                props.fecharModal();
                props.navigation.navigate("DetalheMinhasDenuncias", {
                  protocoloDenuncia: notificacao.protocolo,
                  denunciaId: notificacao.id,
                  vemDasNotificacoes: true,
                });
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
              >
                <FontAwesome name="bell" size={30} color={SUPERNOVA} />
                <CustomText tamanhoFonte={16} customColor={WHITE} isBold>
                  {notificacao?.qtdNaoLido <= 99
                    ? notificacao?.qtdNaoLido
                    : "99+" || ""}
                </CustomText>
              </View>
              <CustomText tamanhoFonte={16} customColor={WHITE} isBold>
                {"Protocolo:\n" + notificacao.protocolo}
              </CustomText>
              <CustomText tamanhoFonte={16} customColor={WHITE} isBold>
                {"\nDescrição:\n" +
                  notificacao.descricao
                    ?.substring(0, 100)
                    ?.concat(
                      notificacao.descricao?.length > 100 ? " [...]" : ""
                    )}
              </CustomText>
            </Pressable>
          );
        })}
      </ScrollView>
    </>
  );
}

export default ChatDenuncia;
