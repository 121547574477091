import React from "react";
import { Image, Pressable, ScrollView, View, Linking } from "react-native";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
import CustomText from "./components/text/CustomText";
import { TIME_AGEFIS } from "./utils/timeAgefis";

import { Styles } from "./styles/Styles";
import { BLACK, BLUE_CHILL, DARK_BLUE, WHITE } from "./utils/cores";

function ModalDevs(props) {
  return (
    <View style={{ flex: 1, backgroundColor: BLUE_CHILL }}>
      <Pressable
        style={[
          {
            backgroundColor: WHITE,
            padding: 10,
            width: 50,
            height: 50,
            display: "flex",
            alignSelf: "flex-end",
            marginTop: 30,
            marginRight: 10,
          },
          Styles.botaoIcone,
        ]}
        onPress={props.fecharModal}
      >
        <FontAwesome name="close" size={24} color={BLACK} />
      </Pressable>
      <View
        style={{
          flexDirection: "column",
          backgroundColor: WHITE,
          margin: 10,
          padding: 10,
          borderRadius: 15,
        }}
      >
        <CustomText
          style={{ textAlign: "center" }}
          tamanhoFonte={30}
          customColor={BLACK}
          isBold
        >
          DESENVOLVEDORES
        </CustomText>
        <CustomText
          style={{ textAlign: "center" }}
          tamanhoFonte={18}
          customColor={BLACK}
          isBold
        >
          Toque nas fotos para mais informações
        </CustomText>
      </View>
      <ScrollView
        style={{ margin: 10 }}
        contentContainerStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {TIME_AGEFIS?.map((t, index) => {
          return (
            <Pressable
              key={`card-${index}`}
              style={{
                backgroundColor: WHITE,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
                width: 250,
                margin: 10,
                padding: 5,
                borderRadius: 10,
              }}
              onPress={async () => await Linking.openURL(t.linkLinkedin)}
            >
              <Image
                source={t.imagem}
                style={{
                  height: 180,
                  width: 180,
                  borderRadius: 100,
                }}
              />
              <CustomText tamanhoFonte={18} customColor={BLACK} isBold>
                {t.nome?.toUpperCase()}
              </CustomText>
              <CustomText tamanhoFonte={18} customColor={BLACK}>
                {t.cargo}
              </CustomText>
              <View
                style={{
                  margin: 5,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View style={{ margin: 5 }}>
                  <AntDesign
                    name="linkedin-square"
                    size={24}
                    color={DARK_BLUE}
                  />
                </View>
                <CustomText
                  style={{
                    margin: 5,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  tamanhoFonte={15}
                  customColor={BLACK}
                >
                  {t.Linkedin}
                </CustomText>
              </View>
            </Pressable>
          );
        })}
      </ScrollView>
    </View>
  );
}

export default ModalDevs;
