import React from "react";
import { Image, ScrollView, View } from "react-native";
import AppIntroSlider from "react-native-app-intro-slider";
import { FontAwesome } from "@expo/vector-icons";
import CustomText from "../../components/text/CustomText";
import { SLIDES } from "./Sliders";
import { BLACK, BLUE_CHILL, WHITE } from "../../utils/cores";
import { Styles } from "../../styles/Styles";
import { eAplicativo } from "../../utils";

const Sobre = ({ navigation }) => {
  const renderizarSlides = ({ item }) => {
    return (
      <View
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          backgroundColor: BLUE_CHILL,
          ...Styles.larguraWeb,
        }}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            resizeMode="contain"
            style={Styles.imagem}
            source={item.image}
          />
          <CustomText
            style={{ textAlign: "center" }}
            tamanhoFonte={25}
            customColor={WHITE}
            isBold
          >
            {item.title}
          </CustomText>
        </View>
        <View
          style={{
            borderRadius: 15,
            backgroundColor: WHITE,
            margin: 10,
            marginBottom: 30,
          }}
        >
          <ScrollView
            scrollIndicatorInsets={{ left: 1 }}
            contentContainerStyle={{ margin: 10 }}
          >
            <CustomText
              style={{ textAlign: "center" }}
              tamanhoFonte={18}
              customColor={BLACK}
            >
              {item.text}
            </CustomText>
          </ScrollView>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        backgroundColor: BLUE_CHILL,
      }}
    >
      <AppIntroSlider
        contentContainerStyle={[
          !eAplicativo && {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        ]}
        showSkipButton
        showPrevButton
        renderItem={renderizarSlides}
        data={SLIDES}
        activeDotStyle={{ backgroundColor: WHITE }}
        onSkip={() => navigation.goBack()}
        onDone={() => navigation.push("Base")}
        renderSkipButton={() => (
          <FontAwesome name="close" size={50} color={WHITE} />
        )}
        renderNextButton={() => (
          <FontAwesome name="arrow-right" size={50} color={WHITE} />
        )}
        renderDoneButton={() => (
          <FontAwesome name="check-circle" size={50} color={WHITE} />
        )}
        renderPrevButton={() => (
          <FontAwesome name="arrow-left" size={50} color={WHITE} />
        )}
      />
    </View>
  );
};

export default Sobre;
