import React, { useEffect, useState } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import { Portal, Modal } from "react-native-paper";
import { FontAwesome5, FontAwesome } from "@expo/vector-icons";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getNotificacoesMensagens } from "./api/chat";

import { Styles } from "./styles/Styles";
import CardBotaoComIcon from "./components/buttons/CardBotaoComIcon";
import LogoView from "./components/visualizacao/LogoView";
import { WHITE } from "./utils/cores";
import CustomText from "./components/text/CustomText";
import ModalNotificacoes from "./ModalNotificacoes";
import { listarBairros } from "./api/bairros";
import { obterPrimeiroNome, redirecionarCaminhoWeb } from "./utils";

function Base({ navigation }) {
  const usuario = useSelector((state) => state?.auth?.user);
  const listaBairros = useSelector((state) => state?.bairros?.list || []);
  const [modalNotificacoes, setModalNotificacoes] = useState(false);

  useEffect(() => redirecionarCaminhoWeb(), []);

  const {} = useQuery(
    "fetchNotificacoesMensagens",
    () => getNotificacoesMensagens(usuario?.id),
    {
      enabled: !!usuario?.id,
    }
  );
  const {} = useQuery("fetchBairros", () => listarBairros(), {
    enabled: listaBairros?.length <= 0,
  });
  let notificacoes = useSelector(
    (state) => state?.chat?.qtdNotificacoesMensagens
  );
  const existeNotificacoes = notificacoes?.count >= 1;

  const getQtdTotalNotificacoes = () => {
    return (
      notificacoes?.list?.reduce((soma, atual) => soma + atual.qtdNaoLido, 0) ||
      0
    );
  };

  const listaBotoes = [
    {
      textoBotao: "CADASTRAR DENÚNCIA",
      urlIconeBotao: require("../assets/telaInicial/criar-denuncia.png"),
      acao: () => navigation.navigate("CadastrarDenuncia"),
    },
    {
      textoBotao: "CONSULTAR DENÚNCIA",
      urlIconeBotao: require("../assets/telaInicial/buscar-denuncia.png"),
      acao: () => navigation.navigate("ConsultarDenuncia"),
    },
    {
      textoBotao: "MINHAS DENÚNCIAS",
      urlIconeBotao: require("../assets/telaInicial/minhas-denuncias.png"),
      acao: () => navigation.navigate("MinhasDenuncias"),
    },
    // {
    //   textoBotao: "CONSULTAR INFRAÇÃO",
    //   urlIconeBotao: require("../assets/telaInicial/minhas-infracoes.png"),
    //   acao: () => navigation.navigate("MinhasInfracoes"),
    // },
  ];

  return (
    <ScrollView
      style={[Styles.containerPrincipal]}
      contentContainerStyle={{ justifyContent: "center" }}
    >
      <Portal>
        <Modal
          contentContainerStyle={Styles.containerModal}
          animationType="slide"
          dismissable
          visible={modalNotificacoes}
          onDismiss={() => setModalNotificacoes(false)}
        >
          <ModalNotificacoes
            fecharModal={() => setModalNotificacoes(false)}
            dados={notificacoes?.list}
            navigation={navigation}
          />
        </Modal>
      </Portal>
      <View style={Styles.barraNomeDenunciante}>
        <CustomText tamanhoFonte={20} isBold>
          {obterPrimeiroNome(usuario?.nome?.toUpperCase() || "DENUNCIANTE")}
        </CustomText>
        <Pressable
          style={Styles.botaoDrawer}
          onPress={() => navigation.openDrawer()}
        >
          <FontAwesome5 name="bars" size={24} color={WHITE} />
        </Pressable>
      </View>
      {existeNotificacoes && (
        <Pressable
          style={{ alignSelf: "flex-end", margin: 10 }}
          onPress={() => setModalNotificacoes(true)}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <FontAwesome name="bell" size={24} color={WHITE} />
            <CustomText tamanhoFonte={20} customColor={WHITE} isBold>
              {(existeNotificacoes && getQtdTotalNotificacoes() <= 99
                ? getQtdTotalNotificacoes()
                : "99+") || "0"}
            </CustomText>
          </View>
        </Pressable>
      )}
      <LogoView />
      <ScrollView contentContainerStyle={Styles.dadosHorizontais}>
        {listaBotoes?.map((botao, index) => (
          <CardBotaoComIcon
            key={`${index}_botao`}
            buttonText={botao?.textoBotao}
            iconComponent={
              <Image
                style={{ width: 64, height: 64 }}
                source={botao?.urlIconeBotao}
              />
            }
            onPress={botao?.acao}
          />
        ))}
      </ScrollView>
    </ScrollView>
  );
}

export default Base;
