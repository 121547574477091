import React from "react";
import { Provider } from "react-redux";
import store from "./src/redux/store";
import { NavigationContainer } from "@react-navigation/native";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { QueryClient, QueryClientProvider } from "react-query";
import { SafeAreaProvider } from "react-native-safe-area-context";

import NavegacaoStack from "./src/utils/navigation";
import { navigationRef } from "./src/utils/navigationService";

const queryClient = new QueryClient();

const theme = {
  ...DefaultTheme,
  roundness: 4,
  colors: { ...DefaultTheme.colors, primary: "#64A9A4", accent: "#55908C" },
};

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <NavigationContainer
          ref={navigationRef}
          documentTitle={{
            formatter: () => `TOTEM - Denúncias`,
          }}
        >
          <SafeAreaProvider>
            <PaperProvider theme={theme}>
              <NavegacaoStack />
            </PaperProvider>
          </SafeAreaProvider>
        </NavigationContainer>
      </Provider>
    </QueryClientProvider>
  );
}
