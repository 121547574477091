import { createSlice } from "@reduxjs/toolkit";

export const BairrosReducer = createSlice({
  name: "bairros",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    BAIRROS_LISTED: (state, action) => {
      state.list = action.payload.list;
      state.count = action.payload.count;
    },
  },
});

export const { BAIRROS_LISTED } = BairrosReducer.actions;
export default BairrosReducer.reducer;
