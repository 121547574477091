import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import {
  Button,
  ProgressBar,
  Colors,
  Portal,
  Dialog,
  Paragraph,
} from "react-native-paper";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";

import LogoView from "../../../components/visualizacao/LogoView";
import { Styles } from "../../../styles/Styles";
import CustomText from "../../../components/text/CustomText";
import { cadastrarDenuncia } from "../../../api/denuncia";
import CustomButton from "../../../components/buttons/CustomButton";
import CardGenerator from "../../../components/card/CardGenerator";
import { GRUPOS_FISCALIZACAO } from "../../../utils/gruposFiscalizacao";
import { BLACK, TUNDORA, WHITE } from "../../../utils/cores";
import { ajustarString, eAplicativo } from "../../../utils";
import { toastr } from "../../../utils/messages";

function Resumo({ navigation }) {
  const dispatch = useDispatch();
  const dadosDenuncia = useSelector((state) => state.cadastroDenuncia);
  const usuario = useSelector((state) => state.auth?.user);
  const loading = useSelector((state) => state.loading?.isLoading);
  const [confirmarVoltar, setConfirmarVoltar] = useState(false);

  const NAO_INFORMADO = "NÃO INFORMADO";

  const handleSubmit = () => {
    const denuncia = {
      turnos: dadosDenuncia.turnos,
      diasFuncionamento: dadosDenuncia.diasFuncionamento,
      grupoDemanda: {
        id: dadosDenuncia.grupoDenuncia,
      },
      fiscalizado: {
        razaoSocial: dadosDenuncia.nomeFiscalizado || NAO_INFORMADO,
      },
      descricao: dadosDenuncia.descricaoDenuncia,
      latitude: dadosDenuncia.latitude || -3.8113137,
      longitude: dadosDenuncia.longitude || -38.5075706,
      dataHoraOcorrencia: dadosDenuncia.dataHoraDenuncia,
      logradouro: dadosDenuncia.logradouro || NAO_INFORMADO,
      numero: dadosDenuncia.numero || NAO_INFORMADO,
      bairro: dadosDenuncia.bairro || NAO_INFORMADO,
      cep: dadosDenuncia.cep || NAO_INFORMADO,
      dataHoraAberturaDemanda: format(new Date(), "dd/MM/yyyy HH:mm:ss"),
      pessoaGeradora: {
        id: usuario?.id,
        razaoSocial: usuario?.nome,
        nomeFantasia: usuario?.nome,
      },
      tags: eAplicativo ? ["APP"] : ["TOTEM"],
    };
    if (usuario === undefined) {
      toastr.error("Feche o aplicativo e abra novamente!");
      return;
    }
    if (loading !== true) cadastrarDenuncia(denuncia);
  };

  const getGrupoDenuncia = (grupoId) => {
    return GRUPOS_FISCALIZACAO.filter((g) => g.id === grupoId).map((g) =>
      ajustarString(g.nome?.toLowerCase())
    );
  };

  return (
    <ScrollView style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="RESUMO DA DENÚNCIA" />
      </View>
      <Portal>
        <Dialog
          visible={confirmarVoltar}
          onDismiss={() => setConfirmarVoltar(false)}
        >
          <Dialog.Content>
            <Paragraph>
              Confirma a ação de voltar para a tela inicial? Todos os dados
              preenchidos serão perdidos!
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setConfirmarVoltar(false)}>Cancelar</Button>
            <Button onPress={() => navigation.navigate("Base")}>Sim</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View style={Styles.larguraWeb}>
        <ProgressBar
          progress={1}
          color={Colors.green50}
          style={{ marginBottom: 10 }}
        />
        <View style={[Styles.containerCard, { backgroundColor: TUNDORA }]}>
          {dadosDenuncia?.grupoDenuncia && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`GRUPO DA DENÚNCIA:\n${getGrupoDenuncia(
                      dadosDenuncia?.grupoDenuncia
                    )}`}
                  </CustomText>
                </>
              }
            />
          )}
          {dadosDenuncia?.nomeFiscalizado && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  {dadosDenuncia.nomeFiscalizado && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`NOME OU RAZÃO SOCIAL\n${dadosDenuncia?.nomeFiscalizado}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.nomeResponsavel && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nNOME DO RESPONSÁVEL\n${dadosDenuncia?.nomeResponsavel}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.telefoneResponsavel && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nTELEFONE: ${dadosDenuncia?.telefoneResponsavel}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.emailResponsavel && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nEMAIL:\n${dadosDenuncia?.emailResponsavel?.toLowerCase()}`}
                    </CustomText>
                  )}
                </>
              }
            />
          )}
          {dadosDenuncia?.logradouro && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`LOGRADOURO\n${dadosDenuncia?.logradouro}`}
                  </CustomText>
                  {dadosDenuncia.cep && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\n\CEP\n${dadosDenuncia?.cep}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.bairro && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nBAIRRO\n${dadosDenuncia?.bairro}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.numero && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nNÚMERO: ${dadosDenuncia?.numero}`}
                    </CustomText>
                  )}
                  {dadosDenuncia.complemento && (
                    <CustomText
                      style={{ margin: 5 }}
                      tamanhoFonte={16}
                      customColor={BLACK}
                      isBold
                    >
                      {`\n\nCOMPLEMENTO: ${dadosDenuncia?.complemento}`}
                    </CustomText>
                  )}
                </>
              }
            />
          )}
          {dadosDenuncia?.turnos && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`TURNOS DE FUNCIONAMENTO\n${ajustarString(
                      dadosDenuncia?.turnos
                        ?.toString()
                        ?.toLowerCase()
                        ?.replace("manha", "manhã")
                    )}`}
                  </CustomText>
                </>
              }
            />
          )}
          {dadosDenuncia?.diasFuncionamento && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`DIAS DE FUNCIONAMENTO\n${ajustarString(
                      dadosDenuncia?.diasFuncionamento
                        ?.toString()
                        ?.toLowerCase()
                        ?.replace("terca", "terça")
                        ?.replace("sabado", "sábado")
                    )}`}
                  </CustomText>
                </>
              }
            />
          )}
          {dadosDenuncia?.dataHoraDenuncia && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`DATA/HORA DA DENÚNCIA\n${dadosDenuncia?.dataHoraDenuncia}`}
                  </CustomText>
                </>
              }
            />
          )}
          {dadosDenuncia?.descricaoDenuncia && (
            <CardGenerator
              semEspacoEntreCards
              alinhamentoTexto="auto"
              corCard={WHITE}
              corTexto={BLACK}
              tipo={"TEXTO_APENAS"}
              tamanhoTexto={16}
              texto={
                <>
                  <CustomText
                    style={{ margin: 5 }}
                    tamanhoFonte={16}
                    customColor={BLACK}
                    isBold
                  >
                    {`DESCRIÇÃO DA DENÚNCIA\n${ajustarString(
                      dadosDenuncia?.descricaoDenuncia?.toLowerCase()
                    )}`}
                  </CustomText>
                </>
              }
            />
          )}
        </View>
        <View style={Styles.containerBotoesDuplos}>
          <CustomButton tipo="VOLTAR" acaoBotao={() => navigation.goBack()} />
          <CustomButton tipo="GRAVAR" acaoBotao={() => handleSubmit()} />
        </View>
        <View style={[Styles.containerBotoesDuplos, { marginBottom: 30 }]}>
          <CustomButton
            tipo="VOLTAR_BASE"
            acaoBotao={() => setConfirmarVoltar(true)}
          />
        </View>
      </View>
    </ScrollView>
  );
}

export default Resumo;
