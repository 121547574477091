import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import loadingReducer from "./slices/loading";
import cadastroDenunciaReducer from "./slices/cadastroDenuncia";
import denunciasReducer from "./slices/denunciasSlice";
import chatReducer from "./slices/chatSlice";
import BairrosReducer from "./slices/bairros";
import AutoReducer from "./slices/autoSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    cadastroDenuncia: cadastroDenunciaReducer,
    denuncias: denunciasReducer,
    chat: chatReducer,
    bairros: BairrosReducer,
    auto: AutoReducer,
  },
});
