import React, { useEffect } from "react";
import { View } from "react-native";
import { List } from "react-native-paper";
import { useSelector } from "react-redux";
import { getMinhasDenuncias } from "../../api/denuncia";

import CustomText from "../../components/text/CustomText";
import { Styles } from "../../styles/Styles";
import LogoView from "../../components/visualizacao/LogoView";
import CardGenerator from "../../components/card/CardGenerator";
import { SITUACOES } from "../../utils/situacoes";
import { BLACK, KEPPEL, TUNDORA, WHITE } from "../../utils/cores";
import { ajustarString, encurtarTextoSeNecessario } from "../../utils";
import { ScrollView } from "react-native-gesture-handler";
import BotaoVoltar from "../../components/buttons/BotaoVoltar";

function MinhasDenuncias({ navigation }) {
  const idUsuarioLogado = useSelector((state) => state.auth.user?.id);
  const denuncias = useSelector((state) => state.denuncias?.list);

  useEffect(() => {
    getMinhasDenuncias(idUsuarioLogado);
  }, []);

  return (
    <View style={Styles.containerPrincipal}>
      <View style={{ marginTop: 20 }}>
        <LogoView titulo="MINHAS DENÚNCIAS" />
      </View>
      <BotaoVoltar />
      <ScrollView style={Styles.larguraWeb}>
        {denuncias?.length > 0 && (
          <View
            style={{
              backgroundColor: TUNDORA,
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              padding: 10,
              borderRadius: 15,
            }}
          >
            <CustomText tamanhoFonte={15} customColor={WHITE} isBold>
              {denuncias?.length + " denúncias cadastradas"}
            </CustomText>
          </View>
        )}
        {denuncias?.length === 0 && (
          <CardGenerator
            semEspacoEntreCards
            alinhamentoTexto="center"
            tipo={"TEXTO_APENAS"}
            texto={"Nenhum registro de denúncia a ser exibido"}
          />
        )}
        <List.AccordionGroup>
          {denuncias?.map((item, index) => (
            <View
              key={`${index}-denuncia`}
              style={[
                Styles.containerCard,
                {
                  backgroundColor: index % 2 === 0 ? KEPPEL : WHITE,
                  alignSelf: "center",
                },
              ]}
            >
              <List.Accordion
                title={
                  <CustomText tamanhoFonte={15} customColor={BLACK} isBold>
                    {`#${item.protocolo}`}
                  </CustomText>
                }
                description={
                  <CustomText tamanhoFonte={15} customColor={BLACK}>
                    {`Descrição: ${ajustarString(
                      item.descricao
                        ?.toLowerCase()
                        ?.substring(0, 50)
                        ?.concat(item.descricao?.length > 50 ? " [...]" : "")
                    )}`}
                  </CustomText>
                }
                id={item.id}
              >
                <View style={Styles.containerLogo}>
                  <CardGenerator
                    tipo={"TEXTO_COM_BOTAO"}
                    alinhamentoTexto="left"
                    tamanhoFonte={15}
                    corCard={index % 2 === 0 ? WHITE : KEPPEL}
                    corBotao={index % 2 === 0 ? KEPPEL : WHITE}
                    acao={() =>
                      navigation.navigate("DetalheMinhasDenuncias", {
                        protocoloDenuncia: item.protocolo,
                        denunciaId: item.id,
                        demandaPrincipalId: item?.demandaPrincipalId,
                      })
                    }
                    tituloBotao={"VER DETALHES"}
                    corTexto={BLACK}
                    texto={
                      <>
                        {item?.protocolo && `PROTOCOLO:\n${item.protocolo}\n\n`}
                        {item?.dataCriacao &&
                          `DATA/HORA DE CRIAÇÃO:\n${item.dataCriacao}\n\n`}
                        {item?.dataHoraOcorrencia &&
                          `DATA/HORA DA DENÚNCIA:\n${item?.dataHoraOcorrencia}\n\n`}
                        {item?.descricao &&
                          `DESCRIÇÃO:\n${encurtarTextoSeNecessario(
                            item?.descricao
                          )}`}
                        {item?.situacao &&
                          `\n\nSITUAÇÃO:\n${ajustarString(
                            SITUACOES[
                              item.situacaoDemandaPrincipal
                                ? item.situacaoDemandaPrincipal
                                : item.situacao
                            ]?.nome?.toLowerCase()
                          )}`}
                      </>
                    }
                  />
                </View>
              </List.Accordion>
            </View>
          ))}
        </List.AccordionGroup>
      </ScrollView>
    </View>
  );
}

export default MinhasDenuncias;
