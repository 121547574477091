import { createSlice } from "@reduxjs/toolkit";

export const DenunciasReducer = createSlice({
  name: "denuncias",
  initialState: {
    list: [],
    detail: [],
  },
  reducers: {
    DENUNCIAS_LISTED: (state, action) => {
      state.list = action.payload;
    },
    DENUNCIAS_DETAIL: (state, action) => {
      state.detail = action.payload;
    },
  },
});

export const { list } = DenunciasReducer.actions;
export default DenunciasReducer.reducer;
