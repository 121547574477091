import consts from "../config";
import {
  dadoExiste,
  eAplicativo,
  irParaFortalezaDigital,
  validarCPFouCNPJ,
} from "../utils";
import { errorHandler, toastr } from "../utils/messages";
import store from "../redux/store";
import * as NavigationService from "../utils/navigationService";
import * as SecureStore from "expo-secure-store";

import axiosClient from "../utils/axiosClient";
import { getMinhasInfracoes } from "./autos";

const urlPrincipal = "/cidadaos";

const salvarLoginLocalmente = async (data) => {
  if (!eAplicativo) {
    localStorage.setItem(consts.USER_KEY, JSON.stringify(data));
    return null;
  }
  await SecureStore.setItemAsync(consts.USER_KEY, JSON.stringify(data));
};

const removerLoginLocal = async () => {
  if (!eAplicativo) {
    localStorage.removeItem(consts.USER_KEY);
    return null;
  }
  await SecureStore.deleteItemAsync(consts.USER_KEY);
};

export function register(params) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .post("/cidadaos", params)
      .then((resp) => {
        store.dispatch({ type: "auth/userLogged", payload: resp.data });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export function login(values) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .post("cidadaos/login", values)
      .then(async (resp) => {
        salvarLoginLocalmente({ ...values, ...resp.data });
        store.dispatch({ type: "auth/userLogged", payload: resp.data });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        logout();
        store.dispatch({ type: "auth/userLogout" });
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export async function logout() {
  removerLoginLocal();
  if (!eAplicativo) {
    window.location.href = consts.URI;
  }
  return store.dispatch({ type: "auth/userLogout" });
}

export function TirarMensagemChat() {
  return store.dispatch({ type: "auth/tirarMensagemChat" });
}

export function passwordRecover(dados) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .get(`${urlPrincipal}/recuperar-senha?email=${dados.email}`)
      .then(() => {
        store.dispatch({ type: "loading/loadingStop" });
        NavigationService.navigate("InserirCodigo", dados);
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export function validarCodigo(data) {
  let prefix = `validarCodigo`;
  store.dispatch({ type: "loading/loadingStart" });
  return [
    axiosClient
      .post(`${urlPrincipal}/validar-codigo`, data)
      .then(() => {
        store.dispatch({ type: `auth/${prefix}`, payload: true });
        store.dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        store.dispatch({ type: `auth/${prefix}`, payload: false });
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export function passwordRecoverCode(dados) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .post(`${urlPrincipal}/recuperar-senha-codigo`, dados)
      .then((resp) => {
        store.dispatch({ type: `auth/validarCodigo`, payload: false });
        store.dispatch({ type: "loading/loadingStop" });
        toastr.success(resp.data.mensagem);
        NavigationService.navigate("Login");
      })
      .catch((e) => {
        store.dispatch({ type: `auth/validarCodigo`, payload: false });
        store.dispatch({ type: "loading/loadingStop" });
        NavigationService.navigate("ResetPassword");
        errorHandler(e);
      }),
  ];
}

export function validateToken(token) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    () => {
      if (token) {
        axiosClient
          .post(`/autenticacao/validar-token`, { token })
          .then((resp) => {
            store.dispatch(
              { type: "TOKEN_VALIDATED", payload: resp.data.valido },
              { type: "loading/loadingStop" }
            );
          })
          .catch((e) => {
            if (e.message.indexOf("A cross-origin error was thrown.") == 0) {
              store.dispatch({ type: "loading/loadingStop" });
            } else {
              store.dispatch(
                { type: "TOKEN_VALIDATED", payload: false },
                { type: "loading/loadingStop" }
              );
            }
            errorHandler(e);
          });
      } else {
        store.dispatch(
          { type: "TOKEN_VALIDATED", payload: false },
          { type: "loading/loadingStop" }
        );
      }
    },
  ];
}

export function savePasswordLoggedUser(params) {
  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .put(`autenticacao/alterar-minha-senha`, {
        id: params.id,
        senha: params.senha,
      })
      .then(() => {
        store.dispatch([{ type: "loading/loadingStop" }]);
        toastr.success("Dados gravados com sucesso!");

        if (params.id) {
          history.back();
        }
      })
      .catch((e) => {
        store.dispatch([{ type: "loading/loadingStop" }]);
        errorHandler(e);
      }),
  ];
}

export function sendPushToken(data) {
  const url = `${urlPrincipal}/registrar-push`;
  return (dispatch) => {
    dispatch({ type: "loading/loadingStart" });
    axiosClient
      .put(url, data)
      .then((resp) => {
        dispatch({ type: "auth/userLogged", payload: resp.data });
        dispatch({ type: "loading/loadingStop" });
      })
      .catch((e) => {
        dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      });
  };
}

export function checkLoginUnico(code) {
  if (code) {
    const params = new URLSearchParams();
    params.append("code", code);
    const url = `${urlPrincipal}/login-fortaleza-digital`;
    store.dispatch({ type: "loading/loadingStart" });

    return [
      axiosClient
        .post(url, params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((resp) => {
          store.dispatch({ type: "auth/userLogged", payload: resp.data });
          store.dispatch({ type: "loading/loadingStop" });

          salvarLoginLocalmente({ ...resp.data });
        })
        .catch((e) => {
          store.dispatch({ type: "loading/loadingStop" });
          removerLoginLocal();

          if (!eAplicativo) {
            irParaFortalezaDigital();
            return null;
          }

          errorHandler(e);
        }),
    ];
  }
  return { type: "APP_READY" };
}

export function desativarConta(id, senha) {
  const params = { id, senha };

  if (!dadoExiste(senha)) {
    toastr.error("Senha não informada!");
    return null;
  }

  const url = `${urlPrincipal}/${id}/desativar-conta`;

  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .put(url, params)
      .then(async () => {
        store.dispatch({ type: "loading/loadingStop" });

        logout();
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
}

export const informarCpfCnpj = (params) => {
  const { id, cpfCnpj } = params;

  if (!dadoExiste(cpfCnpj)) {
    toastr.error("CPF/CNPJ não informado!");
    return null;
  }

  const erroValidacaoCpfCNPJ = validarCPFouCNPJ(cpfCnpj);
  if (dadoExiste(erroValidacaoCpfCNPJ)) {
    toastr.warning(erroValidacaoCpfCNPJ);
    return null;
  }

  const url = `${urlPrincipal}/${id}`;

  return [
    store.dispatch({ type: "loading/loadingStart" }),
    axiosClient
      .put(url, params)
      .then(async (resp) => {
        store.dispatch({ type: "auth/userLogged", payload: resp.data });
        toastr.success("CPF/CNPJ gravado com sucesso!");
        await store.dispatch(getMinhasInfracoes({ cpfCnpj }));
      })
      .catch((e) => {
        store.dispatch({ type: "loading/loadingStop" });
        errorHandler(e);
      }),
  ];
};
