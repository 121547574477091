import { createSlice } from "@reduxjs/toolkit";

export const cadastroDenunciaSlice = createSlice({
  name: "cadastroDenuncia",
  initialState: {
    grupoDenuncia: "",
    nomeFiscalizado: "",
    nomeResponsavel: "",
    telefoneResponsavel: "",
    emailResponsavel: "",
    logradouro: "",
    cep: "",
    numero: "",
    complemento: "",
    bairro: "",
    dataHoraDenuncia: "",
    dataHoraPicker: "",
    descricaoDenuncia: "",
    turnos: "",
    diasFuncionamento: "",
    latitude: "",
    longitude: "",
  },
  reducers: {
    grupoDenunciaSelected: (state, action) => {
      state.grupoDenuncia = action.payload.grupoSelecionado;
    },
    infoFiscalizadoSent: (state, action) => {
      if (action.payload.conheceFiscalizado === "NAO") {
        state.nomeFiscalizado = action.payload.nomeFiscalizado;
      }
      state.nomeFiscalizado = action.payload.nomeFiscalizado;
      state.nomeResponsavel = action.payload.nomeResponsavel;
      state.telefoneResponsavel = action.payload.telefoneResponsavel;
      state.emailResponsavel = action.payload.emailResponsavel;
    },
    localizacaoSent: (state, action) => {
      state.logradouro = action.payload?.logradouro;
      state.bairro = action.payload?.bairro;
      state.cep = action.payload?.cep;
      state.latitude = action.payload?.latitude;
      state.longitude = action.payload?.longitude;
      state.numero = action.payload?.numero ? action.payload?.numero : null;
    },
    complementoSent: (state, action) => {
      state.complemento = action.payload;
    },
    cadastroTurnosDiasFuncionamento: (state, action) => {
      state.turnos = action.payload.turnos;
      state.diasFuncionamento = action.payload.diasFuncionamento;
    },
    dataHoraDenunciaSent: (state, action) => {
      state.dataHoraDenuncia = action.payload?.dataHoraDenuncia;
    },
    descricaoDenunciaSent: (state, action) => {
      state.descricaoDenuncia = action.payload.descricaoDenuncia;
    },
  },
});

export const TURNOS = [
  { label: "Manhã", value: "MANHA" },
  { label: "Tarde", value: "TARDE" },
  { label: "Noite", value: "NOITE" },
];

export const DIAS_FUNCIONAMENTO = [
  { label: "Domingo", value: "DOMINGO" },
  { label: "Segunda", value: "SEGUNDA" },
  { label: "Terça", value: "TERCA" },
  { label: "Quarta", value: "QUARTA" },
  { label: "Quinta", value: "QUINTA" },
  { label: "Sexta", value: "SEXTA" },
  { label: "Sábado", value: "SABADO" },
];

export const { grupoDenunciaSelected } = cadastroDenunciaSlice.actions;
export default cadastroDenunciaSlice.reducer;
